import React from "react";
import PageBanner from "../PageBanner";
import { Container, Row, Col } from "react-bootstrap";
import PriceTabs from "../PriceTabs";
import { Link } from "react-router-dom";
import StepProcess from "../StepProcess";
import WhyChoseus from "../WhyChoseus";
import TradNow from "../TradNow";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
const Banner = {
  backgroundimgage: "./images/33172034_bur12.jpg",
  Title: "ACCOUNTS",

  text: `Experience the benefits of opening secure trading accounts with competitive offerings, zero commission and the tightest spreads.`,
};
const Accounts = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <PageBanner
        backgroundimgage={Banner.backgroundimgage}
        Title={Banner.Title}
        text={Banner.text}
      />
      <section className="featured-services_tables">
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title inner_div_text">
            <h2>Account Types</h2>
            <h3>
              Forex Trading with<span>Space World Capital</span>
            </h3>
            <p>
              Each trading platform has its own array of assets and features
              that shape your trading experience..
            </p>
          </div>
        </div>
        <Container>
          <Row className="my-4 justify-content-center">
            <Col lg={4} md={4} sm={12}>
              <div className="pricelist">
                <div className="titleofpricelist">
                  <h4>WHITE GOLD</h4>
                </div>
                <div className="detailsofpricelist">
                  <ul>
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        INITIAL DEPOSIT <br /> <span>100$</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        LEAVERAGE <br /> <span>0 TO 500</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        COMISSION <br /> <span>0</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SPREAD <br /> <span>2.5 PIP</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SWAP FREE ACCOUNT <br /> <span>YES</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Margin Call <br /> <span>100%</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Stop Out <br /> <span>30 %</span>
                      </div>
                    </li>
                  </ul>
                  <div className="linkofpricelist">
                    <Link to={process.env.REACT_APP_REGISTER} target="_blank">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className="pricelist">
                <div className="titleofpricelist">
                  <h4>DIAMOND</h4>
                </div>
                <div className="detailsofpricelist">
                  <ul>
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        INITIAL DEPOSIT <br /> <span>100$</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        LEAVERAGE <br /> <span>0 TO 500</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        COMISSION <br /> <span>0</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SPREAD <br /> <span>2 PIP</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SWAP FREE ACCOUNT <br /> <span>YES</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Margin Call <br /> <span>100%</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Stop Out <br /> <span>30 %</span>
                      </div>
                    </li>
                  </ul>
                  <div className="linkofpricelist">
                    <Link to={process.env.REACT_APP_REGISTER} target="_blank">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className="pricelist">
                <div className="titleofpricelist">
                  <h4>GOLD</h4>
                </div>
                <div className="detailsofpricelist">
                  <ul>
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        INITIAL DEPOSIT <br /> <span>100$</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        LEAVERAGE <br /> <span>0 TO 500</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        COMISSION <br /> <span>0</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SPREAD <br /> <span>1.5 PIP</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SWAP FREE ACCOUNT <br /> <span>YES</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Margin Call <br /> <span>100%</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Stop Out <br /> <span>30 %</span>
                      </div>
                    </li>
                  </ul>
                  <div className="linkofpricelist">
                    <Link to={process.env.REACT_APP_REGISTER} target="_blank">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className="mt-5">
              <div className="pricelist">
                <div className="titleofpricelist">
                  <h4>SILVER</h4>
                </div>
                <div className="detailsofpricelist">
                  <ul>
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        INITIAL DEPOSIT <br /> <span>100$</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        LEAVERAGE <br /> <span>0 TO 500</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        COMISSION <br /> <span>0</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SPREAD <br /> <span>1 PIP</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SWAP FREE ACCOUNT <br /> <span>YES</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Margin Call <br /> <span>100%</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Stop Out <br /> <span>30 %</span>
                      </div>
                    </li>
                  </ul>
                  <div className="linkofpricelist">
                    <Link to={process.env.REACT_APP_REGISTER} target="_blank">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className="mt-5">
              <div className="pricelist">
                <div className="titleofpricelist">
                  <h4>BRONZE</h4>
                </div>
                <div className="detailsofpricelist">
                  <ul>
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        INITIAL DEPOSIT <br /> <span>5000$</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        LEAVERAGE <br /> <span>0 TO 500</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        COMISSION <br /> <span>0</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SPREAD <br /> <span>0.6 PIP</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SWAP FREE ACCOUNT <br /> <span>YES</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Margin Call <br /> <span>100%</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Stop Out <br /> <span>30 %</span>
                      </div>
                    </li>
                  </ul>
                  <div className="linkofpricelist">
                    <Link to={process.env.REACT_APP_REGISTER} target="_blank">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className="mt-5">
              <div className="pricelist">
                <div className="titleofpricelist">
                  <h4>ECN ACCOUNT</h4>
                </div>
                <div className="detailsofpricelist">
                  <ul>
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        INITIAL DEPOSIT <br /> <span>5000$</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        LEAVERAGE <br /> <span>0 TO 500</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        COMISSION <br /> <span>10$</span>
                      </div>
                    </li>
                    <hr />
                    {/* <li className='liofpricelist'>
                  <div>
                  <i class="fa-regular fa-paper-plane"></i>
                  </div>
                  <div className='textofpricelist'>
                  
                  SPREAD <br /> <span>1 PIP</span>
                  </div>
                </li> */}
                    {/* <hr /> */}
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        SWAP FREE ACCOUNT <br /> <span>YES</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Margin Call <br /> <span>100%</span>
                      </div>
                    </li>
                    <hr />
                    <li className="liofpricelist">
                      <div>
                        <i class="fa-regular fa-paper-plane"></i>
                      </div>
                      <div className="textofpricelist">
                        Stop Out <br /> <span>30 %</span>
                      </div>
                    </li>
                  </ul>
                  <div className="linkofpricelist">
                    <Link to={process.env.REACT_APP_REGISTER} target="_blank">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mt5download ">
        <Container>
          <div className="section-title">
            <h2>Trading Platform</h2>
            <h3>
              Trading <span>Platform</span>
            </h3>
            <h4 className="titleSubText">
              {process.env.REACT_APP_APPNAME} offers you to access easy access
              to innovative <br /> investment tools MT5– available for desktops,
              laptops, and all kinds of mobile devices.
            </h4>
          </div>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <div className="desktop">
                <div className="iconofdesktop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="61"
                    viewBox="0 0 54 61"
                    fill="#000"
                  >
                    <g clip-path="url(#clip0_116_418)">
                      <path
                        d="M52.6367 59.1645C51.2373 58.8687 49.8452 58.5585 48.4458 58.2772L23.8406 53.3648C23.5232 53.2999 23.4077 53.2061 23.4077 52.8671C23.4077 46.1345 23.4077 39.3827 23.4077 32.6116C23.4077 32.6116 23.4077 32.5395 23.4077 32.4169L52.8387 32.9146C52.8387 33.1527 52.8748 33.3475 52.8748 33.5494V58.6162C52.8663 58.7999 52.8469 58.983 52.817 59.1645H52.6367Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M23.4148 28.5866V27.959C23.4148 21.414 23.4148 14.8666 23.4148 8.31674C23.4148 7.84786 23.5447 7.69638 23.9991 7.59539C32.1503 5.98438 40.2993 4.36135 48.4457 2.7263L52.8819 1.84619V28.0816L23.4148 28.5866Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M1.12524 12.1615L19.2383 8.55481V28.6371L1.12524 28.9401V12.1615Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M1.12521 32.0488L19.267 32.3518V52.4269L15.7036 51.7055C11.1014 50.7918 6.5017 49.8709 1.90432 48.9428C1.00985 48.7625 1.12521 48.9428 1.12521 48.0122C1.12521 42.958 1.12521 37.9086 1.12521 32.864V32.0488Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_116_418">
                        <rect
                          width="54"
                          height="59.7131"
                          fill="white"
                          transform="translate(0 0.533447)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="tiltleofdeskto">
                  <h3>
                    Platform for <br />{" "}
                    <span style={{ color: "#D39C60" }}>Desktop</span>
                  </h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_DESKTOP}>
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div
                className="desktop"
                style={{
                  backgroundImage: "url('/images/Web_Photo_Editor (1).jpg')",
                  backgroundSize: "cover",
                }}
              >
                <div className="iconofdesktop">
                  <img src="/images/ANDROID.SVG" alt="" width={100} />
                </div>
                <div className="tiltleofdeskto">
                  <h3>
                    Platform for <br />{" "}
                    <span style={{ color: "#D39C60" }}> Andorid </span>{" "}
                  </h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_ANDROID} target="_blank">
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div
                className="desktop"
                style={{ backgroundImage: "url('/images/ios (1).jpg')" }}
              >
                <div className="iconofdesktop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="49"
                    height="59"
                    viewBox="0 0 49 59"
                    fill="#000"
                  >
                    <g clip-path="url(#clip0_116_410)">
                      <path
                        d="M34.5241 57.3359H33.0955C31.8837 56.8959 30.6584 56.4965 29.4668 55.9819C26.7649 54.8461 23.7292 54.7952 20.9907 55.8398L16.9286 57.3089H15.2903C15.2903 57.3089 15.2428 57.248 15.2157 57.2412C13.3964 56.8695 11.746 55.9196 10.5106 54.5332C8.82677 52.7908 7.38112 50.8329 6.21148 48.7108C3.09046 43.2948 1.38438 37.5402 1.79736 31.2508C1.90344 28.9609 2.50891 26.7219 3.5712 24.6905C5.06674 21.8678 7.44425 19.6116 10.3413 18.2657C12.3543 17.2492 14.6513 16.9415 16.8608 17.3924C18.3503 17.7173 19.8058 18.2116 21.2614 18.6787C22.717 19.1458 24.3148 19.64 25.9193 19.3557C27.0479 19.1169 28.1567 18.7933 29.2366 18.3876C30.8052 17.7648 32.4566 17.3747 34.1381 17.2299C37.3293 17.0947 40.4461 18.2179 42.8175 20.3577C43.5689 20.9941 44.2324 21.7523 44.9635 22.4767C40.7796 25.0155 38.6945 28.624 39.0871 33.4104C39.4798 38.1969 42.0254 41.4939 46.4124 43.3963C46.3786 43.4979 46.365 43.5656 46.3379 43.6265C45.0162 46.7338 43.303 49.6597 41.24 52.3329C39.4865 54.628 37.5639 56.7537 34.5241 57.3359Z"
                        stroke="$000"
                        stroke-width="2.70804"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M34.6933 1.63159C35.6682 7.29817 30.6312 14.3864 24.2132 14.224C24.0066 13.1938 24.0274 12.131 24.2742 11.1097C24.6604 9.35654 25.4334 7.71175 26.5368 6.29562C27.6401 4.8795 29.046 3.72776 30.6515 2.92462C31.3174 2.5854 32.0103 2.30233 32.7232 2.07836C33.3529 1.87526 34.0163 1.78053 34.6933 1.63159Z"
                        stroke="#000"
                        stroke-width="2.70804"
                        stroke-miterlimit="10"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_116_410">
                        <rect
                          width="47.6886"
                          height="58.69"
                          fill="white"
                          transform="translate(0.375732)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="tiltleofdeskto">
                  <h3>
                    Platform for <br />{" "}
                    <span style={{ color: "#D39C60" }}> iPhone </span>
                  </h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_IOS} target="_blank">
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="StartTrad" className="services m-0">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Account</h2>
            <h3>
              Start Forex Trading <span>IN MINUTES</span>{" "}
            </h3>
            <h4 className="titleSubText">
              Start Forex Trading with Us. It’s Easy, and takes only a few
              minutes!
            </h4>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/register.png" alt="#" />
                </figure>
                <h4>Register</h4>
                <p>Register the trading account with your information.</p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/funds.png" alt="#" />
                </figure>
                <h4>Fund</h4>
                <p>Fund your trading with the payment method of your choice.</p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/trade.png" alt="#" />
                </figure>
                <h4>Trade</h4>
                <p>Submit the required KYC and you are ready to trade !</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="m-0 p-0">
        <TradNow />
      </section>
      <section
        id="featured-services"
        className="featured-services noBgImage m-0"
      >
        <div className="container" data-aos="fade-up">
          <div className="section-title inner_div_text">
            <h2>Our Core Value</h2>
            <h3>
              Make Us <span>Expert in Our Field</span>
            </h3>
            <p>
              With years of forex trading expertise, our business has
              established a solid reputation for dependability and trust, and
              we're dedicated to giving our clients the greatest possible
              trading experience.
            </p>
          </div>
          <div className="row services boxborders testCopyst " id="guessstarts">
            <div
              className="col-lg-4 col-md-6 js-tilt commonDIvUI radius1"
              data-aos="fade-right"
              data-aos-delay={100}
            >
              <div className="icon-box-item">
                <div className="icon iconpors">
                  <img src="/images/600c908975f36bacca5af1db_shield.svg" />
                </div>
                <h4>
                  <>Security </>
                </h4>
                <p>
                  We want you to feel that your money invested is like kept in
                  an unbreakable vault.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 js-tilt commonDIvUI radius2"
              data-aos="fade-left"
              data-aos-delay={100}
            >
              <div className="icon-box-item">
                <div className="icon iconpors">
                  <img src="/images/transprancy-removebg-preview.png" />
                </div>
                <h4>
                  <>Transparency</>
                </h4>
                <p>
                  We believe to be transparent with our deeds and commitments
                  made.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 js-tilt commonDIvUI radius1"
              data-aos="fade-right"
              data-aos-delay={100}
            >
              <div className="icon-box-item">
                <div className="icon iconpors">
                  <img src="/images/Teamwork-removebg-preview.png" />
                </div>
                <h4>
                  <>Experienced team</>
                </h4>
                <p>
                  We’re the team of thoroughbred FX traders who were born for
                  trading.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Accounts;
