import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const MianHeader = () => {
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);
  const [menu6, setmenu6] = useState(false);
  const [menu7, setmenu7] = useState(false);
  const [menu8, setmenu8] = useState(false);
  const openmenu1 = () => {
    setmenu1(true);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);

  };
  const openmenu2 = () => {
    setmenu1(false);
    setmenu2(true);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu3 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(true);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu4 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(true);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu5 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(true);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);

    
  };
  const openmenu6 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu7(false);
    setmenu6(true);
    setmenu8(false);
  };
  const openmenu7 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);

  };
  const openmenu8 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(true);

  };
  return (
    <div>
      <div className="mobileview">
        <nav className="mainnav">
          <ul className="logoul">
            <li>
              <Link to={'/'}>

              <img src="/images/Space world capital Logo Final-ai (1).png" className="desktoplogo" alt="" />
              </Link>
            </li>
          </ul>
          <ul className="navbartitle" >
            <li className="li1">
              <div className="">
              <h4 onMouseOver={openmenu1} className="d-flex justify-content-center align-items-center">About {!menu1?<i class="fa-solid fa-angle-down" style={{margin:"0 5px"}}></i>:<i class="fa-solid fa-angle-up" style={{margin:"0 5px"}}></i>}</h4>
              
              </div>
              {menu1 && (
                <div className="submenucontanier" onMouseLeave={() => setmenu1(false)}>
                  <Container className="megamenuconatiner">
                    <Row>
                     
                      <Col  >
                        <ul className="submenu">
                          <Link to={"/aboutus"} onClick={() => setmenu1(false)}>
                            <li>About Us</li>
                          </Link>
                          <Link to={"/regulation"} onClick={() => setmenu1(false)}>
                            <li>Regulation</li>
                          </Link>
                          <Link to={"/faq"} onClick={() => setmenu1(false)}>
                            <li>FAQ</li>
                          </Link>
                          <Link
                            to={"/contactus"}
                            onClick={() => setmenu1(false)}
                          >
                            <li>Contact Us</li>
                          </Link>
                        </ul>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="text-center">
                          <img
                            src="./images/mt5-mobile (1).png"
                            alt=""
                            width={"50%"}
                          />
                        </div>
                        <div className="megamenubtnn text-center">
                          <Link to={"/"} target="_blank" className="commanbtn">
                            Open Live Account
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                  </Container>
                </div>
              )}
            </li>
            <li className="li1">
              <h4 onMouseOver={openmenu2 } className="d-flex justify-content-center align-items-center">Products {!menu2?<i class="fa-solid fa-angle-down" style={{margin:"0 5px"}}></i>:<i class="fa-solid fa-angle-up" style={{margin:"0 5px"}}></i>}</h4>
              {menu2 && (
                <div className="submenucontanier" onMouseLeave={() => setmenu2(false)}>
                  <Container className="megamenuconatiner">
                    <Row>
                      {/* <Col lg={4}>
                        <div className="">
                          <h4>Products</h4>
                          <p>
                          Over 70+ trading instruments across forex , commodities , indices, Stocks.
                          </p>
                        </div>
                      </Col> */}
                      <Col  >
                        <ul className="submenu">
                          <Link to={"/forex"} onClick={() => setmenu2(false)}>
                            <li>Forex</li>
                          </Link>
                          <Link to={"/Metals"} onClick={() => setmenu2(false)}>
                            <li>Metals</li>
                          </Link>
                          <Link to={"/Stocks"} onClick={() => setmenu2(false)}>
                            <li>Stocks</li>
                          </Link>
                          <Link to={"/Indices"} onClick={() => setmenu2(false)}>
                            <li>Indices</li>
                          </Link>
                        </ul>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="text-center">
                          <img
                            src="./images/mt5-mobile (1).png"
                            alt=""
                            width={"50%"}
                          />
                        </div>
                        <div className="megamenubtnn text-center">
                          <Link to={"/"} target="_blank" className="commanbtn">
                            Open Live Account
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                  </Container>
                </div>
              )}
            </li>
            <li className="li1">
              <h4 onMouseOver={openmenu3} className="d-flex justify-content-center align-items-center">Platform {!menu3?<i class="fa-solid fa-angle-down" style={{margin:"0 5px"}}></i>:<i class="fa-solid fa-angle-up" style={{margin:"0 5px"}}></i>}</h4>
              {menu3 && (
                <div className="submenucontanier" onMouseLeave={() => setmenu3(false)}>
                  <Container className="megamenuconatiner">
                    <Row>
                      {/* <Col lg={4}>
                        <div className="">
                          <h4>Platform</h4>
                          <p>
                          MetaTrader 5 (MT5) trading platform meet the growing demands of brokers and traders. MT5 Platform offers all the various features provided by the meta.
                          </p>
                        </div>
                      </Col> */}
                      <Col >
                        <ul className="submenu" >
                          <Link to={"/mt5"} onClick={() => setmenu3(false)}>
                            <li>Meta Trader 5</li>
                          </Link>
                        </ul>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="text-center">
                          <img
                            src="./images/mt5-mobile (1).png"
                            alt=""
                            width={"50%"}
                          />
                        </div>
                        <div className="megamenubtnn text-center">
                          <Link to={"/"} target="_blank" className="commanbtn">
                            Open Live Account
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                  </Container>
                </div>
              )}
            </li>

            <li className="li1">

<Link className="singlemenu" to={'/accounts'} >  <h4 onMouseOver={openmenu4} className="d-flex justify-content-center align-items-center">Account Types</h4></Link>

</li>
            <li className="li1">
              <h4 onMouseOver={openmenu5} className="d-flex justify-content-center align-items-center" >Tools {!menu5?<i class="fa-solid fa-angle-down" style={{margin:"0 5px"}}></i>:<i class="fa-solid fa-angle-up" style={{margin:"0 5px"}}></i>}</h4>
              {menu5 && (
                <div className="submenucontanier" onMouseLeave={() => setmenu5(false)}>
                  <Container className="megamenuconatiner">
                    <Row>
                      {/* <Col lg={4}>
                        <div className="">
                          <h4>Tools</h4>
                          <p>
                          Stay updated with our economic calendar,Live chart , Live Price , Education
                          </p>
                        </div>
                      </Col> */}
                      <Col >
                        <ul className="submenu">
                          <Link
                            to={"/EconomicCalendar"}
                            onClick={() => setmenu5(false)}
                          >
                            <li>Economic Calendar</li>
                          </Link>
                          <Link
                            to={"/Livechart"}
                            onClick={() => setmenu5(false)}
                          >
                            <li>Live Chart</li>
                          </Link>
                          <Link
                            to={"/Liveprice"}
                            onClick={() => setmenu5(false)}
                          >
                            <li>Live Price</li>
                          </Link>
                          <Link
                            to={"/Education"}
                            onClick={() => setmenu5(false)}
                          >
                            <li>Education</li>
                          </Link>
                        </ul>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="text-center">
                          <img
                            src="./images/mt5-mobile (1).png"
                            alt=""
                            width={"50%"}
                          />
                        </div>
                        <div className="megamenubtnn text-center">
                          <Link to={"/"} target="_blank" className="commanbtn">
                            Open Live Account
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                  </Container>
                </div>
              )}
            </li>
            <li className="li1">
              <h4 onMouseOver={openmenu6} className="d-flex justify-content-center align-items-center">Partner {!menu6?<i class="fa-solid fa-angle-down" style={{margin:"0 5px"}}></i>:<i class="fa-solid fa-angle-up" style={{margin:"0 5px"}}></i>}</h4>
              {menu6 && (
                <div className="submenucontanier" onMouseLeave={() => setmenu6(false)}>
                  <Container className="megamenuconatiner">
                    <Row>
                      {/* <Col lg={4}>
                        <div className="">
                          <h4>Partner</h4>
                          <p>
                           
Boost Your Prospective Income Become A {process.env.REACT_APP_APPNAME} Introducing Broker
                          </p>
                        </div>
                      </Col> */}
                      <Col >
                        <ul className="submenu">
                          <Link
                            to={"/Partners"}
                            onClick={() => setmenu6(false)}
                          >
                            <li>Introducing Broker</li>
                          </Link>
                        </ul>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="text-center">
                          <img
                            src="./images/mt5-mobile (1).png"
                            alt=""
                            width={"50%"}
                          />
                        </div>
                        <div className="megamenubtnn text-center">
                          <Link to={"/"} target="_blank" className="commanbtn">
                            Open Live Account
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                  </Container>
                </div>
              )}
            </li>
            <li className="li1">
              <h4 onMouseOver={openmenu8} className="d-flex justify-content-center align-items-center">Calculator {!menu8?<i class="fa-solid fa-angle-down" style={{margin:"0 5px"}}></i>:<i class="fa-solid fa-angle-up" style={{margin:"0 5px"}}></i>}</h4>
              {menu8 && (
                <div className="submenucontanier" onMouseLeave={() => setmenu8(false)}>
                  <Container className="megamenuconatiner">
                    <Row>
                      {/* <Col lg={4}>
                        <div className="">
                          <h4>Partner</h4>
                          <p>
                           
Boost Your Prospective Income Become A {process.env.REACT_APP_APPNAME} Introducing Broker
                          </p>
                        </div>
                      </Col> */}
                      <Col >
                        <ul className="submenu">
                          <Link
                            to={"/margin-calculator"}
                            onClick={() => setmenu8(false)}
                          >
                            <li>Margin Calculator </li>
                          </Link>
                          <Link
                            to={"/pip-calculator"}
                            onClick={() => setmenu8(false)}
                          >
                            <li>Pip Calculator</li>
                          </Link>
                        </ul>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="text-center">
                          <img
                            src="./images/mt5-mobile (1).png"
                            alt=""
                            width={"50%"}
                          />
                        </div>
                        <div className="megamenubtnn text-center">
                          <Link to={"/"} target="_blank" className="commanbtn">
                            Open Live Account
                          </Link>
                        </div>
                      </Col> */}
                    </Row>
                  </Container>
                </div>
              )}
            </li>
            <li className="li1">

             <Link className="singlemenu" to={'/Academy'} >  <h4 onMouseOver={openmenu7} className="d-flex justify-content-center align-items-center">Academy</h4></Link>
             
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MianHeader;
