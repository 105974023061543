import React from "react";
import PageBanner from "../PageBanner";
import { Container, Row, Col } from "react-bootstrap";
import Advantages from "../Advantages";
import ServicesMain from "../ServicesMain";
import TradNow from "../TradNow";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
const Banner = {
  backgroundimgage: "./images/10701724_19187756.jpg",
  Title: "About Us",

  text: `Introducing Space World Capital Ltd: Your Trusted Global Business Partner. Our Leadership Team Boasts a Wealth of Experience.`,
};
const ourmission = {
  img: "./images/pngegg (6).png",
  title: "Our Mission",
  subtitle: `${process.env.REACT_APP_APPNAME} Values`,

  text: "our mission is to empower individuals and institutions to achieve financial success through the forex market. We are dedicated to delivering innovative trading solutions, education, and unmatched support to help our clients navigate the complexities of currency trading effectively.",
};
const ourvesion = {
  img: "./images/pngwing.com (10).png",
  title: "Our Vision",
  subtitle: `${process.env.REACT_APP_APPNAME} Values`,
  order: "imgcol",
  text: "our vision is to be the driving force behind the financial success of our clients and partners worldwide. We aspire to create a global community of informed and empowered traders who confidently navigate the complexities of the forex market.",
};
const Aboutus = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <PageBanner
        backgroundimgage={Banner.backgroundimgage}
        Title={Banner.Title}
        text={Banner.text}
      />
      {/* <section>
            <Container>
                <Row>
                    <div className="text-center">
                        <h2 className="sectiontitle"> OUR PRODUCTS</h2>
                    </div>
                </Row>
                <Row>
                    <Col lg={3} md={6} sm={12} data-aos="flip-left">
                    <div className='iconimage text-center'>
                        <img src="./images/wired-gradient-153-bar-chart (1).gif" alt=""  />
                        <h4>FOREX</h4>
                    </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} data-aos="flip-right">
                    <div className='iconimage text-center'>
                        <img src="./images/wired-gradient-945-dividends.gif" alt=""  />
                        <h4>STOKES</h4>
                    </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} data-aos="flip-left">
                    <div className='iconimage text-center'>
                        <img src="./images/wired-gradient-943-commodity.gif" alt=""  />
                        <h4> Metals</h4>
                    </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} data-aos="flip-right">
                    <div className='iconimage text-center'>
                        <img src="./images/wired-gradient-298-coins.gif" alt=""  />
                        <h4>INDICES</h4>
                    </div>
                    </Col>
                </Row>
            </Container>
        </section> */}
      <section
        id="featured-services"
        className="featured-services noBgImage m-0 p-0"
      >
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title inner_div_text">
            <h2>About Us</h2>
            <h3>
              About <span>{process.env.REACT_APP_APPNAME}</span>
            </h3>
            <p>
            Welcome to Space World Capital. Our unwavering commitment is to facilitate seamless access to the global financial markets for both individuals and businesses. With a steadfast mission, we empower traders and investors to navigate the intricate landscape of global currency markets with confidence.
            </p>
            <p>
              
At Space World Capital, we offer more than just a trading platform. We provide a superior trading experience through a comprehensive range of meticulously crafted products, cutting-edge technology, and unparalleled services. Our team of seasoned experts stands ready to assist you at every step, ensuring that you maximize your trading profits.
            </p>
            <p>Join us on this exciting journey as we unlock opportunities and elevate your trading endeavors</p>
          </div>
        </div>
      </section>
      <section id="featured-services" className="featured-services noBgImage m-0">
  <div className="container" data-aos="fade-up">
    <div className="section-title inner_div_text">
      <h2>Our Core Value</h2>
      <h3>
      Make Us <span>Expert in Our Field</span>
      </h3>
      <p>
      With years of forex trading expertise, our business has established a solid reputation for dependability and trust, and we're dedicated to giving our clients the greatest possible trading experience.
      </p>
    </div>
    <div className="row services boxborders testCopyst " id="guessstarts">
      <div
        className="col-lg-4 col-md-6 js-tilt commonDIvUI radius1"
        data-aos="fade-right"
        data-aos-delay={100}
      >
        <div className="icon-box-item">
          <div className="icon iconpors">
            <img src="/images/600c908975f36bacca5af1db_shield.svg"  />
          </div>
          <h4>
            <>Security </>
          </h4>
          <p>
          At Space World Capital, we ensure that your invested funds are safeguarded like an unbreakable vault, fortified with multiple layers of encryption.
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 col-md-6 js-tilt commonDIvUI radius2"
        data-aos="fade-left"
        data-aos-delay={100}
      >
        <div className="icon-box-item">
          <div className="icon iconpors">
            <img src="/images/transprancy-removebg-preview.png"  />
          </div>
          <h4>
            <>Transparency</>
          </h4>
          <p>
          We are committed to transparency in our actions and the promises we make.
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 col-md-6 js-tilt commonDIvUI radius1"
        data-aos="fade-right"
        data-aos-delay={100}
      >
        <div className="icon-box-item">
          <div className="icon iconpors">
            <img src="/images/Teamwork-removebg-preview.png"  />
          </div>
          <h4>
            <>Experienced team</>
          </h4>
          <p>
          We are a team of seasoned FX traders, deeply ingrained in the art of trading.
          </p>
        </div>
      </div>
     
     
    </div>
  </div>
</section>
      <section className="m-0 p-0">
        <TradNow />
      </section>
      <section
        id="featured-services"
        className="featured-services noBgImage m-0 p-0"
      >
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title inner_div_text">
            <h2>Space World Capital</h2>
            <h3>
              Trading Forex with<span>{process.env.REACT_APP_APPNAME}</span>
            </h3>
            <p>
              With years of forex trading expertise, our business has
              established a solid reputation for dependability and trust, and
              we're dedicated to giving our clients the greatest possible
              trading experience.
            </p>
          </div>
          <div className="row">
            <div
              className="paddinreemocvew col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box abt-div js-tilt">
                <div className="icon">
                  <img src="/images/Training+and+consultancy@2x.png" />
                </div>
                <h4 className="title">
                  <a href="#">Who We Are</a>
                </h4>
                <p className="description">
                  {" "}
                  space world capital Ltd has gained name internationally for its brand by offering investors an exceptional trading environment
                  free from conflicts of interest. We are among the most
                  reliable online forex traders in the whole globe.
                </p>
              </div>
            </div>
            <div
              className="paddinreemocvew col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="icon-box abt-div js-tilt">
                <div className="icon">
                  <img src="/images/Strategy@2x.png" />
                </div>
                <h4 className="title">
                  <a href="#">Our Mission</a>
                </h4>
                <p className="description">
                Our mission is to provide the best services and creative solutions to our clients and help them grow together in this fast paced global financial market. 
                </p>
              </div>
            </div>
            <div
              className="paddinreemocvew col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="icon-box abt-div js-tilt">
                <div className="icon">
                  <img src="/images/Consultancy@2x.png" />
                </div>
                <h4 className="title">
                  <a href="#">Our Vision</a>
                </h4>
                <p className="description">
                Our overall vision is to be the best global FX Company. We aim to achieve this by providing the highest standards of customer service and ensuring that our clients are treated fairly. We want to be your trustworthy global partner in the forex market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* <section>
        <Advantages/>
       </section>
       <section>
        <ServicesMain img={ourmission.img} title={ourmission.title}  subtitle={ourmission.subtitle} text={ourmission.text}/>
       </section>
    
       <section>
        <ServicesMain img={ourvesion.img} title={ourvesion.title}  subtitle={ourvesion.subtitle} text={ourvesion.text} order={ourvesion.order}/>
       </section> */}
    </div>
  );
};

export default Aboutus;
