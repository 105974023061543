import React from "react";
import PageBanner from "../PageBanner";
import WhyChoseus from "../WhyChoseus";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Dwonloadbeoswr from "../Dwonloadbeoswr";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Banner = {
  backgroundimgage: "./images/networl2.jpg",
  Title: "INTRODUCING BROKERS",

  text: `Expand your business and build your client network while benefitting from our IB.`,
};

const IntruductingBroker = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <PageBanner
        backgroundimgage={Banner.backgroundimgage}
        Title={Banner.Title}
        text={Banner.text}
      />
    <section className="m-0 p-0">
      <Container>
      <Row>
    
    <div className="section-title inner_div_text">
      <h2>Partner</h2>
      <h3>INTRODUCING  <span>BROKERS</span></h3>
      <p>An Introducing Broker (IB) is an individual or a company that has a direct relationship with clients, and may make recommendations to them and take their trade orders, while delegating the execution of trades to a company like us. At {process.env.REACT_APP_APPNAME}, we offer plenty of reasons for you to choose our services, including benefits to both yourself and your clients.</p>

     
     
    </div>
  </Row>
      </Container>
    </section>
      <section className="sectionmt5dwonload">
        <Container>
        <div className="section-title inner_div_text">
      <h2>Partner</h2>
      <h3> Why Partner with  <span> {process.env.REACT_APP_APPNAME}</span></h3>
     

     
     
    </div>
          <Row className="mt5dwenloadrow">
            <Col lg={6} md={6} sm={12} className="mt5dwn" data-aos="fade-right">
              <div>
                <img src="./images/downlad-MT5_1.png" alt="" width={"90%"} />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} data-aos="fade-left">
              <div className="mt5dwn">
                <h2 className="mt5heading">
                  We are not just The Best, <br />
                  We also Offer the Best
                </h2>

                <p className="mt5p">
                  {process.env.REACT_APP_APPNAME} offers an opportunity to all
                  Introducing Brokers to gain more benefits than you can count.
                </p>
                <div className="ibuldiv">
                  <ul className="ibul">
                    <li>Incredible Prize Offers!</li>
                    <li>Swap Free Accounts</li>
                  </ul>
                  <ul className="ibul">
                    <li>Expert Account Managers!</li>
                    <li>Guaranteed Unbeatable</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="StartTrad" className="services m-0">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Partner</h2>
            <h3>
             How To Become <span>IB</span>{" "}
            </h3>
            <h4 className="titleSubText">
            Expand your business and build your client network while benefitting from our IB.
            </h4>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/register.png" alt="#" />
                </figure>
                <h4>Register</h4>
                <p>Register  and sign up for an IB agreement</p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="/images/group-qa8k4ydxcy2ljvpf9wxot4wio40pcsxtxd8wqj3voi.png" alt="#" />
                </figure>
                <h4> Introduce</h4>
                <p>Refer clients to {process.env.REACT_APP_APPNAME} </p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/trade.png" alt="#" />
                </figure>
                <h4> EARN</h4>
                <p>Grow your revenue as your clients trade!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} data-aos="fade-left">
              <img
                src="./images/mt5-platform-hero2.webp"
                alt=""
                width={"100%"}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="ib2div">
                <h4>
                  Who is the Introducing Broker <br />
                  Program designed for?
                </h4>
                <p>
                  {" "}
                  Introducing Broker (IB) program works best with the partners
                  with large trader community outreach
                </p>
                <p>Some of our key & potential partners include:</p>
                <div className="ibuldiv">
                  <ul className="ibul">
                    <li>Educators</li>
                    <li>Signal Providers</li>
                    <li>FinTech Firms</li>
                  </ul>
                  <ul className="ibul">
                    <li>Rebate Providers</li>
                    <li>EA Providers</li>
                    <li>Referrers</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
  
  
    </div>
  );
};

export default IntruductingBroker;
