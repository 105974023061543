import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);
  const [menu6, setmenu6] = useState(false);
  const [menu7, setmenu7] = useState(false);
  const [menu8, setmenu8] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setScrollToTop(false);
    }
  }, [scrollToTop]);
  const openmenu1 = () => {
    setmenu1(!menu1);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);
  };
  const openmenu2 = () => {
    setmenu1(false);
    setmenu2(!menu2);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu3 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(!menu3);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu4 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(!menu4);
    setmenu5(false);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu5 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(!menu5);
    setmenu6(false);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu6 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(!menu6);
    setmenu7(false);
    setmenu8(false);


  };
  const openmenu7 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu7(!menu7);
    setmenu6(false);
    setmenu8(false);


  };
  const openmenu8 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu8(!menu8);
    setmenu6(false);
    setmenu7(false);


  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setScrollToTop(true);
  };

  return (
    <Box sx={{ display: "flex",backgroundColor:"#000000" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <div className="logo text-center">
            <Link to={"/"} className="tex-center" onClick={()=>setScrollToTop(true)}>
              <img
                src="/images/photo_5825784264560590878_c (1).jpg"
                className="logoimg "
                style={{padding:'10px 0'}}
                alt="logo"
              />
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem>
            <ul className="sidebarnav">
              <li>
                <span onClick={openmenu1}>
                  About
                  <i class="fa-solid fa-plus" onClick={openmenu1}></i>
                </span>
                <Divider />
                {menu1 && (
                  <ul className="">
                    <Link to={"/aboutus"} onClick={handleDrawerClose}>
                      <li>About Us</li>
                    </Link>
                    <Link to={"/regulation"} onClick={handleDrawerClose}>
                      <li>Regulation</li>
                    </Link>
                    <Link to={"/faq"} onClick={handleDrawerClose}>
                      <li>faq</li>
                    </Link>

                    <Link to={"/contactus"} onClick={handleDrawerClose}>
                      <li>Contact Us</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={openmenu2}>
                  Products
                  <i class="fa-solid fa-plus" onClick={openmenu2}></i>
                </span>
                <Divider />
                {menu2 && (
                  <ul className="">
                    <Link to={"/forex"} onClick={handleDrawerClose}>
                      <li>Forex</li>
                    </Link>
                    <Link to={"/Metals"} onClick={handleDrawerClose}>
                      <li>Metals</li>
                    </Link>
                    <Link to={"/Stocks"} onClick={handleDrawerClose}>
                      <li>Stocks</li>
                    </Link>
                    <Link to={"/Indices"} onClick={handleDrawerClose}>
                      <li>Indices</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={openmenu3}>
                  Platform
                  <i class="fa-solid fa-plus" onClick={openmenu3}></i>
                </span>
                {menu3 && (
                  <ul className="">
                    <Link to={"/mt5"} onClick={handleDrawerClose}>
                      <li>Meta Trader 5</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={openmenu4}>
                Account Types
                  <i class="fa-solid fa-plus" onClick={openmenu4}></i>
                </span>
                {menu4 && (
                  <ul className="">
                    <Link to={"/accounts"} onClick={handleDrawerClose}>
                      <li>Account Types</li>
                    </Link>
                   
                  </ul>
                )}
              </li>
              <li>
                <span onClick={openmenu5}>
                  Tools
                  <i class="fa-solid fa-plus" onClick={openmenu5}></i>
                </span>
                {menu5 && (
                  <ul className="">
                    <Link to={"/EconomicCalendar"} onClick={handleDrawerClose}>
                      <li>EconomicCalendar</li>
                    </Link>
                    <Link to={"/Livechart"} onClick={handleDrawerClose}>
                      <li>Livechart</li>
                    </Link>
                    <Link to={"/Liveprice"} onClick={handleDrawerClose}>
                      <li>Liveprice</li>
                    </Link>

                    <Link to={"/Education"} onClick={handleDrawerClose}>
                      <li>Education</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={openmenu6}>
                  Partner
                  <i class="fa-solid fa-plus" onClick={openmenu6}></i>
                </span>
                {menu6 && (
                  <ul className="">
                    <Link to={"/Partners"} onClick={handleDrawerClose}>
                      <li>Introducing Broker</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={openmenu8}>
                Calculator
                  <i class="fa-solid fa-plus" onClick={openmenu8}></i>
                </span>
                {menu8 && (
                  <ul className="">
                    <Link to={"/margin-calculator"} onClick={handleDrawerClose}>
                      <li>Margin Calculator</li>
                    </Link>
                    <Link to={"/pip-calculator"} onClick={handleDrawerClose}>
                      <li>Pip Calculator</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={openmenu7}>
                Academy
                  <i class="fa-solid fa-plus" onClick={openmenu7}></i>
                </span>
                {menu7 && (
                  <ul className="">
                    <Link to={"/Academy"} onClick={handleDrawerClose}>
                      <li>Academy</li>
                    </Link>
                  </ul>
                )}
              </li>
              <li>
                <Link to={process.env.REACT_APP_REGISTER} target="_blank" className="btnsidebar">
                  Open Live Account
                </Link>
              </li>
              <li>
                <Link to={process.env.REACT_APP_DEMO} target="_blank" className="btnsidebar">
                  Open Demo Account
                </Link>
              </li>
              <li>
                <Link to={process.env.REACT_APP_LOGIN} target="_blank" className="btnsidebar">
                  Log In
                </Link>
              </li>
            </ul>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

const LogoHeader = () => {
  const [opensidebar, setopensiderbar] = useState(false);
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);
  const [menu6, setmenu6] = useState(false);
  const [menu7, setmenu7] = useState(false);
  const openmenu1 = () => {
    setmenu1(!menu1);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
  };
  const openmenu2 = () => {
    setmenu1(false);
    setmenu2(!menu2);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
  };
  const openmenu3 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(!menu3);
    setmenu4(false);
    setmenu5(false);
    setmenu6(false);
  };
  const openmenu4 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(!menu4);
    setmenu5(false);
    setmenu6(false);
  };
  const openmenu5 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(!menu5);
    setmenu6(false);
  };
  const openmenu6 = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
    setmenu6(!menu6);
  };

  return (
    <div>
      <div className="logoheader">
        <div className="sidebar">
          <i
            class="fa-solid fa-bars"
            onClick={() => setopensiderbar(!opensidebar)}
          ></i>
        </div>
        <div className="">
          <div className="leftsidedetauls">
            <div >
            <i class="fa-solid fa-envelope"></i>
            <span>support@spaceworldcapital.com</span>
            
            </div>
            <div>
            <i class="fa-solid fa-mobile"></i>
            <span>+971 45751990</span>

            </div>
          </div>
        </div>
        <div className="headerbtn text-end">
          <Link to={process.env.REACT_APP_REGISTER} target="_blank" className="">
          <i class="fa-solid fa-user-group"></i><span> Open Real Account</span>
          </Link>
        
          <Link to={process.env.REACT_APP_DEMO} target="_blank" className="">
          <i class="fa-solid fa-user-group"></i><span> Open Demo Account</span>
          </Link>
          <Link to={process.env.REACT_APP_LOGIN} target="_blank" className="">
          <i class="fa-solid fa-user-group"></i><span> Become An IB</span>
          </Link>
          <Link to={process.env.REACT_APP_LOGIN} target="_blank" className="" style={{border:"none"}}>
          <i class="fa-solid fa-user-group"></i><span> Login</span>
          </Link>
          
         
        </div>
        <div className="sidebar">
          <Link to={"/"} target="_blank" className="commanbtn link">
            {" "}
            <i class="fa-solid fa-right-to-bracket"></i>
          </Link>
        </div>
      </div>

      <div className="sidebarnew">
        <PersistentDrawerLeft />
      </div>
    </div>
  );
};

export default LogoHeader;
