import React from 'react'
import PageBanner from '../PageBanner'
import { Container,Row,Col } from 'react-bootstrap'
import { ForexCrossRates } from "react-ts-tradingview-widgets";
import WhyChoseus from '../WhyChoseus';
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';
const Banner={

    backgroundimgage:"./images/6b71e1fb22f7f322b791588f1dc19183.jpeg",
    Title:"LIVE PRICE",
    
    text:`You’ll find real-time rates on currencies, commodities, and indices..`
  
  }

const Liveprice = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div>
           <PageBanner backgroundimgage={Banner.backgroundimgage} Title={Banner.Title} text={Banner.text} />
           <section className='m-0 p-0'>
      <Container>
        <Row>
    
          <div className="section-title inner_div_text">
            <h2>Trading Tool</h2>
            <h3>Live  <span>Price</span></h3>
            <p>Identify patterns and trends and respond to price action more effectively by typing in your chosen asset. Apply moving averages, Bollinger Bands, and other technical indicators to enhance your trading.</p>

           
           
          </div>
        </Row>

      </Container>
    </section>
    <section className='m-0'>
      <Container>
      <Row>
        <div className="section-title">
            <h2>Live Price</h2>
            <h3>
            Stay updated with our <span>Live Price.</span>
            </h3>
   
          </div>
        </Row>
        <Row>
        
<ForexCrossRates colorTheme="light" height={450} width={'100%'} ></ForexCrossRates>
        </Row>
      </Container>
    </section>

    </div>
  )
}

export default Liveprice