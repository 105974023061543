import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setScrollToTop(false);
    }
  }, [scrollToTop]);
  function showmenu1() {
    setmenu1(!menu1);
  }
  function showmenu2() {
    setmenu2(!menu2);
  }
  function showmenu3() {
    setmenu3(!menu3);
  }
  function showmenu4() {
    setmenu4(!menu4);
  }
  function showmenu5() {
    setmenu5(!menu5);
  }
  return (
    <div>
      <section className="footersection">
        <Container>
          <Row className="footrrow">
            <Col lg={2} sm={12} md={6}>
              <h5 className="footerttitle">LINKS</h5>

              <ul className="footerul">
                <li>
                  {" "}
                  <Link to={process.env.REACT_APP_LOGIN} target="_blank">
                    Log In
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to={process.env.REACT_APP_REGISTER} target="_blank">
                    {" "}
                    Live Account
                  </Link>
                </li>
                <li>
                  <Link to={"/aboutus"} onClick={() => setScrollToTop(true)}>
                    About Us
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/faq"} onClick={() => setScrollToTop(true)}>
                    FAQ
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col lg={2} sm={12} md={6}>
              <h5 className="footerttitle">ACCOUNTS</h5>
              <ul className="footerul">
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                  BRONZE
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                  SILVER
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                  GOLD
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                  DIAMOND
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                  WHITE GOLD
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                  ECN ACCOUNT
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col lg={2} sm={12} md={6}>
              <h5 className="footerttitle">TRADING PLATFORM</h5>
              <ul className="footerul">
                <li>
                  <Link to={"/MT5"} onClick={() => setScrollToTop(true)}>
                    MT5
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col lg={2} sm={12} md={6}>
              <h5 className="footerttitle">MARKTES</h5>
              <ul className="footerul">
                <li>
                  <Link to={"/Forex"} onClick={() => setScrollToTop(true)}>
                    Forex
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/Metals"} onClick={() => setScrollToTop(true)}>
                    Metals
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/Indices"} onClick={() => setScrollToTop(true)}>
                    Indices
                  </Link>{" "}
                </li>
                <li>
                  <Link to={"/Stocks"} onClick={() => setScrollToTop(true)}>
                    Stocks
                  </Link>{" "}
                </li>
              </ul>
            </Col>
            <Col lg={4} sm={12} md={6}>
              <h5 className="footerttitle">CONTACT</h5>
              <ul className="footerul">
                <li>
                  <i class="fa-solid fa-phone"></i>{" "}
                  <span>{process.env.REACT_APP_MOBILENO}</span>
                </li>
                <li>
                  <i class="fa-solid fa-envelope"></i>
                  <span>{process.env.REACT_APP_EMAIL}</span>
                </li>
                <li className="d-flex ">
                  <i class="fa-solid fa-location-dot"></i>
                  <span>Physical Address: {process.env.REACT_APP_PHYSICAL_ADDRESS}</span>{" "}
                </li>
                <li className="d-flex ">
                  <i class="fa-solid fa-location-dot"></i>
                  <span>Registered Address: {process.env.REACT_APP_REGISTER_ADDRESS}</span>{" "}
                </li>
                <li>
                  <i class="fa-solid fa-clock mt-2"></i>
                  <span>Timing 9.00 am to 5.30 pm Dubai time</span>{" "}
                </li>
              </ul>
            </Col>
          </Row>

          {/* formobile view */}
          <Container className="forfootermobile">
            <Row>
              <Col>
                <h5 className="footerttitle" onClick={showmenu1}>
                  LINKS{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu1}></i>
                </h5>

                {menu1 && (
                  <ul className="footerul">
                    <li>
                      {" "}
                      <Link to={"/"} target="_blank">
                        Log In
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to={"/"} target="_blank">
                        {" "}
                        Live Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/aboutus"}
                        onClick={() => setScrollToTop(true)}
                      >
                        About Us
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to={"/faq"} onClick={() => setScrollToTop(true)}>
                        FAQ
                      </Link>{" "}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu2}>
                  ACCOUNTS{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu2}></i>
                </h5>
              </div>

              {menu2 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    BRONZE
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    SILVER
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    GOLD
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    DIAMOND
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    WHITE GOLD
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/accounts"} onClick={() => setScrollToTop(true)}>
                    ECN ACCOUNT
                    </Link>{" "}
                  </li>
                </ul>
              )}
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu3}>
                  MARKTES{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu3}></i>
                </h5>
              </div>
              {menu3 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <Link to={"/Forex"} onClick={() => setScrollToTop(true)}>
                      Forex
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/Metals"} onClick={() => setScrollToTop(true)}>
                      Metals
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/Indices"} onClick={() => setScrollToTop(true)}>
                      Indices
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={"/Stocks"} onClick={() => setScrollToTop(true)}>
                      Stocks
                    </Link>{" "}
                  </li>
                </ul>
              )}
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu4}>
                  TRADING PLATFORM{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu4}></i>
                </h5>
              </div>
              {menu4 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <Link to={"/MT5"} onClick={() => setScrollToTop(true)}>
                      MT5
                    </Link>{" "}
                  </li>
                </ul>
              )}
            </Row>
            <Row>
              <div>
                <h5 className="footerttitle" onClick={showmenu5}>
                CONTACT{" "}
                  <i class="fa-solid fa-caret-down" onClick={showmenu5}></i>
                </h5>
              </div>
              {menu5 && (
                <ul className="footerul" style={{ marginLeft: "30px" }}>
                  <li>
                    <i class="fa-solid fa-phone"></i>{" "}
                    <span>{process.env.REACT_APP_MOBILENO}</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-envelope"></i>
                    <span>{process.env.REACT_APP_EMAIL}</span>
                  </li>
                  <li>
                    <i class="fa-solid fa-location-dot"></i>
                    <span>{process.env.REACT_APP_PHYSICAL_ADDRESS}</span>{" "}
                  </li>
                  <li>
                    <i class="fa-solid fa-clock"></i>
                    <span>Timing 9.00 am to 5.30 pm Dubai time</span>{" "}
                  </li>
                </ul>
              )}
            </Row>
          </Container>

          <Row>
            <hr style={{ color: "#fff", margin: "10px 0" }} />
          </Row>
          <Row>
            <div className="text-center">
              <ul className="socialmediaul">
                <li>
                  <Link to={"https://www.linkedin.com/in/space-world-capital-526934303?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"https://www.instagram.com/invites/contact/?i=7v7pz1k2ola1&utm_content=u9cf1c1"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"https://x.com/capital_sp59931?t=2YJpROCAA48kyjec4x0c4A&s=09"} target="_blank">
                  <i class="fa-brands fa-x-twitter"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link to={"https://www.youtube.com/@SpaceWorldCapital"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-youtube"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"https://www.t.me/SWC1603"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-telegram"></i>
                  </Link>
                </li>
                <li>
                  <Link to={"https://facebook.com/spaceworldcapital"} target="_blank">
                    {" "}
                    <i class="fa-brands fa-facebook"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Row>
          <Row>
            <hr style={{ color: "#fff", margin: "5px 0" }} />
          </Row>
          <Row>
            <p className="footertext">
              <strong> Risk Warning:</strong> Investing in derivative products
              carries significant risks and may not be suitable for all
              investors. Leveraging in these instruments can increase the level
              of risk and potential loss exposure. Before making any decision to
              engage in foreign exchange trading or CFDs, it is essential to
              carefully assess your investment objectives, level of experience,
              and risk tolerance. You should only invest funds that you can
              afford to lose. We strongly encourage you to educate yourself
              thoroughly about the associated risks and, if you have any
              questions, seek advice from an independent financial or tax
              advisor.
                <br />
                <p className="my-2">   <strong> Restricted Regions:</strong>  Space World Capital Ltd does not provide services to the residents of certain countries, including the United States of America, New Zealand, North Korea, Iran, Israel, Canada or other countries</p>
            </p>
          </Row>
          <Row>
            <hr style={{ color: "#fff", margin: "5px 0" }} />
          </Row>
          <Row>
            <ul className="footerul copyright">
              <li>
                <i class="fa-regular fa-copyright"></i>
                <span>{process.env.REACT_APP_APPNAME}</span>
              </li>
              <li>
                <i class="fa-solid fa-phone"></i>{" "}
                <span>{process.env.REACT_APP_MOBILENO}</span>
              </li>
              <li className="">
                <i class="fa-solid fa-envelope"></i>
                <span> {process.env.REACT_APP_EMAIL}</span>
              </li>
             
            </ul>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Footer;
