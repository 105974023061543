import React from "react";
import PageBanner from "../PageBanner";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Advantages from "../Advantages";
import Faqs from "../Faqs";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const Banner = {
  backgroundimgage: "./images/10701724_19187756.jpg",
  Title: "Meta Trader 5",

  text: `Meta Trader 5 (MT5) trading platform meets the growing demands of brokers and traders. MT5 Platform offers all the various features provided by the Meta.
  `,
};
const FAQs = [
  {
    title: "Do you offer MT5 Trading Platform?",
    body: "Yes, we offer MT5 trading across our Standard, Pro and ECN trading accounts.",
  },
  {
    title: "What features will I get with the MT5 platform with Us",
    body: `Signing up with a MetaTrader 5 account with us will give you access to several benefits. You’ll be able to trade with leverage up to 1:500, social trading, advanced trading MT5 indicators, 24/7 technical support, and much more.`,
  },
  {
    title: "How can I download MT5??",
    body: `You can download our mobile app including both MT5 platforms online as per your device with either iOS or Android. Alternatively for web trading, you can download MT5 for PC or Mac devices.`,
  },
  {
    title: "Do you offer social trading with an MT5 account?",
    body: "Yes we do. By choosing  us your trusted broker, you’ll be able to select and copy the best MT5 traders on the platform, based on your capital and risk appetite.",
  },
];

const MT5 = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <PageBanner
        backgroundimgage={Banner.backgroundimgage}
        Title={Banner.Title}
        text={Banner.text}
      />
      <section className="m-0 p-0">
        <Container>
          <Row>
          <div className="section-title">
            <h2>Platform</h2>
            <h3>
            MT5 PLATFORM <span>FEATURES</span>{" "}
            </h3>
            <h4 className="titleSubText">Most Advanced Trading Platform </h4>
          </div>
          </Row>
          <Row className="advantegesrow">
            <Col lg={4} md={12} sm={12} data-aos="fade-right">
              <div className="text-left">
                <div className="advantegestitle">
                  <h4>
                    Tradable products: Forex, Metals, Shares, Indices,
                    Commodities, Cryptocurrencies
                  </h4>
                </div>
                <ul className="feturexul">
                  <li>Execution Type: Market Order</li>
                  <li>Market depth of latest price quotes</li>
                  <li>Alert notifications to track important market events</li>
                  <li>
                    80+ technical analysis indicators and analytical tools
                  </li>
                  <li>
                    Maximum Lots per Click: 30 for FX; 10 for Metals; 5 for
                    Shares, Indices, Commodities, and Cryptocurrencies
                  </li>
                  <li>
                    Minimum Lots per Click: 0.01 for FX & Metals; 0.1 for
                    Shares, Indices, Commodities, and Cryptocurrencies
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div className="text-center" data-aos="flip-left">
                <img src="./images/mt5 features.webp" alt="" width={"100%"} />
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} data-aos="fade-left">
              <div className="text-right">
                <div className="advantegestitle">
                  <h4>
                    Powerful algorithmic trading with the built-in MQL5
                    development environment
                  </h4>
                </div>
                <ul className="feturexul">
                  <li>Stop Out Level: 50%</li>
                  <li>One-click Trading</li>
                  <li>VPS Hosting</li>
                  <li>Full EA functionality</li>
                  <li>
                    Superior fundamental analysis including financial news and
                    economic calendar
                  </li>
                  <li>Social Trading functionality</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="sectionmt5dwonload">
        
        <Container>
        <Row>
          <div className="section-title">
            <h2>Download Now</h2>
            <h3>
            DOWNLOAD<span>MT5</span>{" "}
            </h3>
            <h4 className="titleSubText">Most Advanced Trading Platform </h4>
          </div>
          </Row>
          <Row className="mt5dwenloadrow">
            <Col lg={6} md={6} sm={12} data-aos="fade-up-right">
              <div className="mt5dwn">
                <img src="./images/downlad-MT5_1.png" alt="" width={"90%"} />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} data-aos="fade-up-left">
              <div className="mt5dwn">
                <h2 className="mt5heading">DOWNLOAD THE MT5 PLATFORM</h2>
                <img src="./images/MT5-Logo-Full.svg" alt="" />
                <p className="mt5p">
                  {" "}
                  Select a download type as per your device operating system and
                  your account regulation:
                </p>
                <div className="mt5btn">
                  <Link to={"/"} target="_blank" className="dwnbtn">
                    <i class="fa-brands fa-windows"></i>
                  </Link>
                  <Link to={"/"} target="_blank" className="dwnbtn">
                    <i class="fa-brands fa-apple"></i>
                  </Link>
                  <Link to={"/"} target="_blank" className="dwnbtn">
                    <i class="fa-solid fa-mobile"></i>
                  </Link>
                  <Link to={"/"} target="_blank" className="dwnbtn">
                    <i class="fa-solid fa-globe"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="mt5download ">
        <Container>
        <div className="section-title">
            <h2>Trading Platform</h2>
            <h3>
            Trading  <span>Platform</span>
            </h3>
            <h4 className="titleSubText">
            {process.env.REACT_APP_APPNAME} offers  you to access easy access to innovative <br /> investment tools MT5– available for desktops, laptops, and all kinds of mobile devices.
            </h4>
          </div>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <div className="desktop">
                <div className="iconofdesktop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="61"
                    viewBox="0 0 54 61"
                    fill="#000"
                  >
                    <g clip-path="url(#clip0_116_418)">
                      <path
                        d="M52.6367 59.1645C51.2373 58.8687 49.8452 58.5585 48.4458 58.2772L23.8406 53.3648C23.5232 53.2999 23.4077 53.2061 23.4077 52.8671C23.4077 46.1345 23.4077 39.3827 23.4077 32.6116C23.4077 32.6116 23.4077 32.5395 23.4077 32.4169L52.8387 32.9146C52.8387 33.1527 52.8748 33.3475 52.8748 33.5494V58.6162C52.8663 58.7999 52.8469 58.983 52.817 59.1645H52.6367Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M23.4148 28.5866V27.959C23.4148 21.414 23.4148 14.8666 23.4148 8.31674C23.4148 7.84786 23.5447 7.69638 23.9991 7.59539C32.1503 5.98438 40.2993 4.36135 48.4457 2.7263L52.8819 1.84619V28.0816L23.4148 28.5866Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M1.12524 12.1615L19.2383 8.55481V28.6371L1.12524 28.9401V12.1615Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M1.12521 32.0488L19.267 32.3518V52.4269L15.7036 51.7055C11.1014 50.7918 6.5017 49.8709 1.90432 48.9428C1.00985 48.7625 1.12521 48.9428 1.12521 48.0122C1.12521 42.958 1.12521 37.9086 1.12521 32.864V32.0488Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_116_418">
                        <rect
                          width="54"
                          height="59.7131"
                          fill="white"
                          transform="translate(0 0.533447)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="tiltleofdeskto">
                  <h3>Platform for <br /> <span style={{color:"#D39C60"}}>Desktop</span></h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_DESKTOP} >
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div className="desktop" style={{backgroundImage:"url('/images/Web_Photo_Editor (1).jpg')",backgroundSize:"cover",}}>
                <div className="iconofdesktop">

<img src="/images/ANDROID.SVG" alt="" width={100} />
                </div>
                <div className="tiltleofdeskto">
                  <h3>
Platform for <br /> <span style={{color:"#D39C60"}}> Andorid </span> </h3>
                </div>
                <div className="desktoplink">
                  <Link  to={process.env.REACT_APP_ANDROID} target="_blank">
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>

                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div className="desktop" style={{backgroundImage:"url('/images/ios (1).jpg')"}}>
                <div className="iconofdesktop">
                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="59" viewBox="0 0 49 59" fill="#000"><g clip-path="url(#clip0_116_410)"><path d="M34.5241 57.3359H33.0955C31.8837 56.8959 30.6584 56.4965 29.4668 55.9819C26.7649 54.8461 23.7292 54.7952 20.9907 55.8398L16.9286 57.3089H15.2903C15.2903 57.3089 15.2428 57.248 15.2157 57.2412C13.3964 56.8695 11.746 55.9196 10.5106 54.5332C8.82677 52.7908 7.38112 50.8329 6.21148 48.7108C3.09046 43.2948 1.38438 37.5402 1.79736 31.2508C1.90344 28.9609 2.50891 26.7219 3.5712 24.6905C5.06674 21.8678 7.44425 19.6116 10.3413 18.2657C12.3543 17.2492 14.6513 16.9415 16.8608 17.3924C18.3503 17.7173 19.8058 18.2116 21.2614 18.6787C22.717 19.1458 24.3148 19.64 25.9193 19.3557C27.0479 19.1169 28.1567 18.7933 29.2366 18.3876C30.8052 17.7648 32.4566 17.3747 34.1381 17.2299C37.3293 17.0947 40.4461 18.2179 42.8175 20.3577C43.5689 20.9941 44.2324 21.7523 44.9635 22.4767C40.7796 25.0155 38.6945 28.624 39.0871 33.4104C39.4798 38.1969 42.0254 41.4939 46.4124 43.3963C46.3786 43.4979 46.365 43.5656 46.3379 43.6265C45.0162 46.7338 43.303 49.6597 41.24 52.3329C39.4865 54.628 37.5639 56.7537 34.5241 57.3359Z" stroke="$000" stroke-width="2.70804" stroke-miterlimit="10"></path><path d="M34.6933 1.63159C35.6682 7.29817 30.6312 14.3864 24.2132 14.224C24.0066 13.1938 24.0274 12.131 24.2742 11.1097C24.6604 9.35654 25.4334 7.71175 26.5368 6.29562C27.6401 4.8795 29.046 3.72776 30.6515 2.92462C31.3174 2.5854 32.0103 2.30233 32.7232 2.07836C33.3529 1.87526 34.0163 1.78053 34.6933 1.63159Z" stroke="#000" stroke-width="2.70804" stroke-miterlimit="10"></path></g><defs><clipPath id="clip0_116_410"><rect width="47.6886" height="58.69" fill="white" transform="translate(0.375732)"></rect></clipPath></defs></svg>
                </div>
                <div className="tiltleofdeskto">
                  <h3>
Platform for <br /> <span style={{color:"#D39C60"}}> iPhone </span></h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_IOS} target="_blank" >
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} data-aos="fade-up-right">
              <div className="text-center">
                <img src="./images/what-is-metatrader5_1.svg" alt="" />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} data-aos="fade-up-left">
              <div className="whatis-mt5">
                <h2 className="whatis-mt5-title">WHAT IS MT5?</h2>
                <p>
                  MT5 gives you access to our extensive line up of asset classes
                  — including forex, stocks, indices, metals, — on a single
                  trading platform. With complete access to advanced trade types
                  and cutting-edge analytical tools, Space World Capital Ltd pushes
                  the MT5 experience to a new level, that’s whether you’re a new
                  or experienced trader. The main benefit of the MT5 platform is
                  that traders are not restricted to using the built-in
                  analytical resources.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    
      <section className="m-0 p-0">
        <Container>
          <Row>
            <div className="text-center">
              <h2 className="sectiontitle">Frequently Asked Question</h2>
            </div>
          </Row>
          <Row>
            {FAQs.map((item) => (
              <Faqs title={item.title} body={item.body} />
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default MT5;
