import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Pagenotfound = () => {
  return (
    <div>
        <section>
            <Container>
                <Row>
                    <div className="text-center">
                        <h2 className="sectiontitle">404</h2>
                        <h2 className="sectiontitle">Page Not Found</h2>
                        <Link to={'/'} className='commanbtn'>Home</Link>
                    </div>
                </Row>
            </Container>
        </section>
    </div>
  )
}

export default Pagenotfound