import React from 'react'
import PageBanner from '../PageBanner'
import { MarketData } from "react-ts-tradingview-widgets";
import { Container ,Row,Col} from 'react-bootstrap';
import WhyChoseus from '../WhyChoseus';
import TradNow from '../TradNow';
import Pricelist from '../Pricelist';
import TabsNavi from '../TabsNavi';
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
const Banner={

    backgroundimgage:"./images/opoy7.jpg",
    Title:"Indices",
    
    text:`Gain instant access and trade major stock exchange indices from across the globe, including NASDAQ, German DAX Index, US Wall Street 30 Index and more..`

}
const Indices = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
        <PageBanner backgroundimgage={Banner.backgroundimgage} Title={Banner.Title} text={Banner.text} />
        
      <section className="featured-services_tables m-0 p-0 ">
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title inner_div_text">
            <h2>Indices</h2>
            <h3>
              Indices Trading with<span>{process.env.REACT_APP_APPNAME}</span>
            </h3>
            
            <p>
            Index trading is the buying and selling of financial instruments that are linked to stock market indices that track the performance of groups of assets based on certain characteristics such as industry, sector, country or growth rate.
            </p>
            <p>
            Indices are a way to track the performance of specific industries or the stock of the largest and most well-known firms in the world. You may make predictions about the movements of the largest global financial markets by investing in CFDs on indices without having to do in-depth research on individual stocks.
            </p>
          </div>
        </div>
      </section>
       <section className='m-0'>
        <Container>
            <Row>
            <div className="section-title">
            <h2>Indices Trading</h2>
            <h3>
            Indices <span>Trading</span>{" "}
            </h3>
   
          </div>
            </Row>
            <Row>
          

<MarketData colorTheme="light" width="100%" height={400}></MarketData>
            </Row>
        </Container>
       </section>
       <section className='m-0 p-0'>
        <TradNow/>
       </section>
       <section id="featured-services" className="featured-services noBgImage m-0">
  <div className="container" data-aos="fade-up">
    <div className="section-title inner_div_text">
      <h2>Our Core Value</h2>
      <h3>
      Make Us <span>Expert in Our Field</span>
      </h3>
      <p>
      With years of forex trading expertise, our business has established a solid reputation for dependability and trust, and we're dedicated to giving our clients the greatest possible trading experience.
      </p>
    </div>
    <div className="row services boxborders testCopyst " id="guessstarts">
      <div
        className="col-lg-4 col-md-6 js-tilt commonDIvUI radius1"
        data-aos="fade-right"
        data-aos-delay={100}
      >
        <div className="icon-box-item">
          <div className="icon iconpors">
            <img src="/images/600c908975f36bacca5af1db_shield.svg"  />
          </div>
          <h4>
            <>Security </>
          </h4>
          <p>
          We want you to feel that your money invested is like kept in an unbreakable vault.
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 col-md-6 js-tilt commonDIvUI radius2"
        data-aos="fade-left"
        data-aos-delay={100}
      >
        <div className="icon-box-item">
          <div className="icon iconpors">
            <img src="/images/transprancy-removebg-preview.png"  />
          </div>
          <h4>
            <>Transparency</>
          </h4>
          <p>
          We believe to be transparent with our deeds and commitments made.
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 col-md-6 js-tilt commonDIvUI radius1"
        data-aos="fade-right"
        data-aos-delay={100}
      >
        <div className="icon-box-item">
          <div className="icon iconpors">
            <img src="/images/Teamwork-removebg-preview.png"  />
          </div>
          <h4>
            <>Experienced team</>
          </h4>
          <p>
          We’re the team of thoroughbred FX traders who were born for trading.
          </p>
        </div>
      </div>
     
     
    </div>
  </div>
</section>

    </div>
  )
}

export default Indices