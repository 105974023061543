import React from "react";
import { useState } from "react";
import PageBanner from "../PageBanner";
import WhyChoseus from "../WhyChoseus";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
const Banner = {
  backgroundimgage: "./images/10701724_19187756.jpg",
  Title: "Academy",

  
};

const Academy = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [box1, setbox1] = useState(false);
  const [box2, setbox2] = useState(false);
  const [box3, setbox3] = useState(false);
  const [box4, setbox4] = useState(false);
  function hendleclick() {
    setbox1(true);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hendleclick2() {
    setbox1(false);
    setbox2(true);
    setbox3(false);
    setbox4(false);
  }
  function hendleclick3() {
    setbox1(false);
    setbox2(false);
    setbox3(true);
    setbox4(false);
  }
  function hendleclick4() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(true);
  }
  function hidedata() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hidedata2() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hidedata3() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hidedata4() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  return (
    <div>
      <PageBanner
        backgroundimgage={Banner.backgroundimgage}
        Title={Banner.Title}
        text={Banner.text}
      />
  <>
  <section id="featured-services" className="featured-services noBgImage mt-0">
    <div className="container-fluid" data-aos="fade-up">
      <div className="section-title inner_div_text">
        <h2>Academy</h2>
        <h3>
          What is <span>Forex Market</span>
        </h3>
        <p>
          The Forex market is where banks, businesses, governments, investors
          and traders come to exchange and speculate on currencies. The Forex
          market is also referred to as the 'Fx market','Currency market’,
          'Foreign exchange currency market’ or 'Foreign currency market’, and
          it is the largest and most liquid market in the world with an average
          daily turnover of $ 5.3 trillion.
        </p>
      </div>
    </div>
  </section>
  <section id="aboutCops" className="about section-bg foexBGIng">
    <div className="container" data-aos="fade-up">
      {/* <div class="section-title">
               <h2>Fortune FX</h2>
               <h3>Find Out More <span>About Us</span></h3>
               <p>We have best experience in online forex trading, stocks, commodities, cryptos & stock market..</p>
               </div> */}
      <div className="row flexverticals">
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/untitled_10-ai.png" />
        </div>
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Advantages of Forex Trading:
          </h3>
          <div className="posepw">
            <p>
              The Fx market is open 24 hours a day, 5 days a week with the most
              important world trading centers being located in London, New York,
              Tokyo, Zurich, Frankfurt, Hong Kong, Singapore, Paris, and Sydney
              Forex is the largest market in the world, with daily volumes
              exceeding $5.3 trillion per day. Trade whenever you want: There is
              no opening bell in the Forex market. You can enter or exit a trade
              whenever you want from Sunday around 5pm EST to Friday around 4pm
              EST.
            </p>
            <p>
              <strong>Ease of access:</strong> You can fund your trading account
              with as little as $100 at many retail brokers and begin trading
              the same day in some cases. Straight through order execution
              allows you to trade at the click of a mouse.
            </p>
            <p>
              Fewer currency pairs to focus on, instead of getting lost trying
              to analyze thousands of stocks. Freedom to trade anywhere in the
              world with the only requirements being a laptop and internet
              connection.
            </p>
            <p>
              Commission-free trading with many retail market-makers and overall
              lower transaction costs than stocks and commodities. Volatility
              allows traders to profit in any market condition and provides for
              high-probability weekly trading opportunities. Also, there is no
              structural market bias like the long bias of the stock market, so
              traders have equal opportunity to profit in rising or falling
              markets.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    id="featured-services"
    className="featured-services masteopsw aboutCopssdsd3"
  >
    <div className="container-fluid" data-aos="fade-up">
      <div className="section-title">
        <h3>Forex Terminologies</h3>
      </div>
      <div className="row">
        <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="icon-box js-tilt">
            <div className="icon">
              <img src="images/forex.png" />
            </div>
            <h4 className="title">
              <a href="#" style={{ pointerEvents: "none" }}>
                PIP
              </a>
            </h4>
            <p className="description">
              The smallest increment of price movement a currency can make. Also
              called point or points. For example, 1 pip for the EUR/USD =
              0.0001 and 1 pip for the USD/JPY = 0.01..
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="icon-box js-tilt">
            <div className="icon">
              <img src="images/gold-ingot.png" />
            </div>
            <h4 className="title">
              <a href="#" style={{ pointerEvents: "none" }}>
                LEVERAGE
              </a>
            </h4>
            <p className="description">
              {" "}
              Leverage is the ability to gear your account into a position
              greater than your total account margin. For instance, if a trader
              has $1,000 of margin in his account and he opens a $100,000
              position, he leverages his account by 100 times, or 100:1
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={300}
        >
          <div className="icon-box js-tilt">
            <div className="icon">
              <img src="images/bar-chart.png" />
            </div>
            <h4 className="title">
              <a href="#" style={{ pointerEvents: "none" }}>
                MARGIN
              </a>
            </h4>
            <p className="description">
              The deposit required to open or maintain a position. Margin can be
              either “free” or “used”.Used margin is that amount which is being
              used to maintain an open position, whereas free margin is the
              amount available to open new positions. With a $1,000 margin
              balance in your account and a 1% margin requirement to open a
              position, you can buy or sell a position worth up to a notional
              $100,000. This allows a trader to leverage his account by up to
              100 times or a leverage ratio of 100:1.
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={400}
        >
          <div className="icon-box js-tilt">
            <div className="icon">
              <img src="images/cryptocurrency.png" />
            </div>
            <h4 className="title">
              <a href="#" style={{ pointerEvents: "none" }}>
                SPREAD
              </a>
            </h4>
            <p className="description">
              The difference between the sell quote and the buy quote or the bid
              and offer price. For example, if EUR/USD quotes read 1.3200/03,
              the spread is the difference between 1.3200 and 1.3203, or 3 pips.
              In order to break even on a trade, a position must move in the
              direction of the trade by an amount equal to the spread.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    id="featured-services"
    className="featured-services noBgImage mt-0 nomargintops"
  >
    <div className="container-fluid" data-aos="fade-up">
      <div className="section-title inner_div_text">
        <h3>
          Currency <span>Pairs</span>
        </h3>
        <br />
        <h2>Base/Quote currencies</h2>
        <p>
          The first currency in the pair that is located to the left of the
          slash mark is called the base currency, and the second currency of the
          pair that's located to the right of the slash market is called the
          counter or quote currency.
        </p>
        <p>
          If you buy the EUR/USD (or any other currency pair), the exchange rate
          tells you how much you need to pay in terms of the quote currency to
          buy one unit of the base currency. In other words, in the example
          above, you have to pay 1.32105 U.S. dollars to buy 1 euro.
        </p>
      </div>
    </div>
  </section>
  <section id="aboutCops" className="about section-bg foexBGIng">
    <div className="container" data-aos="fade-up">
      {/* <div class="section-title">
               <h2>Fortune FX</h2>
               <h3>Find Out More <span>About Us</span></h3>
               <p>We have best experience in online forex trading, stocks, commodities, cryptos & stock market..</p>
               </div> */}
      <div className="row flexverticals">
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="posepw">
            <p>
              If you sell the EUR/USD (or any other currency pair), the exchange
              rate tells you how much of the quote
            </p>
            <p>
              Currency you receive for selling one unit of the base currency. In
              other words, in the example above, you will receive 1.32105 U.S.
              dollars if you sell 1 euro.
            </p>
          </div>
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Bid-Ask Price:
          </h3>
          <div className="posepw">
            <p>
              {" "}
              <strong>Bid Price </strong>
              Bid Price - The bid is the price at which the market (or your
              broker) will buy a specific currency pair from you. Thus, at the
              bid price, a trader can sell the base currency to their broker.
            </p>
            <p>
              {" "}
              <strong>Ask Price </strong>The ask price is the price at which the
              market (or your broker) will sell a specific currency pair to you.
              Thus, at the ask price you can buy the base currency from your
              broker.
            </p>
          </div>
        </div>
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="/images/cryoti.png" />
        </div>
      </div>
    </div>
  </section>
  <section
    id="featured-services"
    className="featured-services noBgImage mt-0 nomargintops"
  >
    <div className="container-fluid" data-aos="fade-up">
      <div className="section-title inner_div_text">
        <h3>
          Order <span>Types</span>
        </h3>
        <br />
        <h2>Long &amp; Short</h2>
        <p>
          Another great thing about the Forex market is that you have more of a
          potential to profit in both rising and falling markets due to the fact
          that there is no market bias like the bullish bias of stocks. Anyone
          who has traded for a while knows that the fastest money is made in
          falling markets, so if you learn to trade both bull and bear markets
          you will have plenty of opportunities to profit.
        </p>
      </div>
    </div>
  </section>
  <section id="orderTypes" className="about section-bg foexBGIng">
    <div className="container" data-aos="fade-up">
      {/* <div class="section-title">
               <h2>Fortune FX</h2>
               <h3>Find Out More <span>About Us</span></h3>
               <p>We have best experience in online forex trading, stocks, commodities, cryptos & stock market..</p>
               </div> */}
      <div className="row flexverticals">
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="/images/orderType.jpeg" />
        </div>
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="posepw">
            <p>
              If you sell the EUR/USD (or any other currency pair), the exchange
              rate tells you how much of the quote
            </p>
            <p>
              Currency you receive for selling one unit of the base currency. In
              other words, in the example above, you will receive 1.32105 U.S.
              dollars if you sell 1 euro.
            </p>
          </div>
          <div className="posepw">
            <p>
              {" "}
              <strong>LONG</strong>
              When we go long it means we are buying the market and so we want
              the market to rise so that we can then sell back our position at a
              higher price than we bought for. This means we are buying the
              first currency in the pair and selling the second. So, if we buy
              the EURUSD and the euro strengthens relative to the U.S. dollar,
              we will bein a profitable trade.
            </p>
            <p>
              {" "}
              <strong>SHORT</strong>When we go short it means we are selling the
              market and so we want the market to fall so that we can then buy
              back our position at a lower price than we sold it for. This means
              we are selling the first currency in the pair and buying the
              second. So, if we sell the GBPUSD and the British pound weakens
              relative to the U.S. dollar, we will bein a profitable trade.
            </p>
          </div>
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Market Order:
          </h3>
          <div className="posepw">
            <p>
              A market orderisan order thatis placed ‘atthe market’ and it’s
              executed instantly at the best available price.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="aboutCops" className="about section-bg foexBGIng">
    <div className="container" data-aos="fade-up">
      {/* <div class="section-title">
               <h2>Fortune FX</h2>
               <h3>Find Out More <span>About Us</span></h3>
               <p>We have best experience in online forex trading, stocks, commodities, cryptos & stock market..</p>
               </div> */}
      <div className="row flexverticals mb-bop">
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Limit Order
          </h3>
          <div className="posepw">
            <p>
              {" "}
              A limit entry order is placed to either buy below the current
              market price or sell above the current market price. Thisis a bit
              tricky to understand at first so let me explain:
            </p>
            <p>
              {" "}
              If the EURUSD is currently trading at 1.3200 and you want to go
              sell the market if it reaches 1.3250, you can place a limit sell
              order and then when / if the market touches 1.3250 it will fill
              you short. Thus, the limit sell order is placed ABOVE current
              market price. If you want to buy the EURUSD at 1.3050 and the
              market is trading at 1.3100, you would place your limit buy order
              at 1.3050 and then if the market hits that level it will fill you
              long. Thus the limit buy order is placed BELOW current market
              price.
            </p>
          </div>
        </div>
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/limiyOrder.png" />
        </div>
      </div>
      <div className="row flexverticals mb-bop">
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/buy_sell.png" />
        </div>
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Buy Stop &amp; Sell Stop Order
          </h3>
          <div className="posepw">
            <p>
              A stop-entry order is placed to buy above the current market price
              or sell below it. For example, if you want to trade long but you
              want to enter on a breakout of a resistance area, you would place
              your buy stop just above the resistance and you would get filled
              as price moves up into your stop entry order. The opposite holds
              true for asell-stop entry if you want to sell the market.
            </p>
          </div>
        </div>
      </div>
      <div className="row flexverticals mb-bop">
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Stop Loss Order
          </h3>
          <div className="posepw">
            <p>
              {" "}
              A stop-loss order isan order thatis connected to a trade for the
              purpose of preventing further losses if the price moves beyond a
              level that you specify. The stop-loss is perhaps the most
              important order in Forex trading since it gives you the ability to
              control your risk and limit losses. This order remains in effect
              until the position is liquidated or you modify or cancel the
              stop-loss order.
            </p>
          </div>
        </div>
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/stopLoss.png" />
        </div>
      </div>
    </div>
  </section>
  <section id="featured-services" className="featured-services noBgImage mt-0">
    <div className="container-fluid" data-aos="fade-up">
      <div className="section-title inner_div_text">
        <h3>
          Trailing <span>Stop Loss</span>
        </h3>
        <p>
          The trailing stop-loss order is an order that is connected to a trade
          like the standard stop-loss,but a trailing stop-loss moves or ‘trails’
          the current market price as your trade moves in your favor. You can
          typically set your trailing stop-loss to trail at a certain distance
          from current market price, it will not start moving until or unless
          the price moves greater than the distance you specify. For example, if
          you set a 50 pip trailing stop on the EURUSD, the stop will not move
          up until your position is in your favor by 51 pips, and then the stop
          will only move again if the market moves 51 pips above where your
          trailing stop is, so this way you can lock in profit as the market
          moves in your favor while still giving the trade room to grow and
          breath. Trailing stops are best used instrong trending markets
        </p>
      </div>
    </div>
  </section>
  {/* <section id="aboutCops" className="about section-bg foexBGIng">
    <div className="container" data-aos="fade-up">
      <div className="row flexverticals">
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/stoplossorder.png" />
        </div>
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            GTC/GFD/0OCO/OTO:
          </h3>
          <div className="posepw mtospeos">
            <p>
              <strong>Good till Cancelled order (GTC) :</strong> YA good till
              cancelled order is exactly what it says...good until you cancel
              it.
            </p>
            <p>
              <strong>Good for the Day order (GFD) :</strong> A good for day
              order remains active in the market until the end of the trading
              day, in Forex the trading day ends at 5:00pm EST or New York time.
            </p>
            <p>
              <strong>One Cancels the Other order (OCO) :</strong>A one cancels
              the other order is essentially two sets of orders; it can consist
              of two entry orders, two stop loss orders, or two entry and two
              stop-loss orders. Essentially, when one orderis executed the
              otheris cancelled.
            </p>
            <p>
              <strong>One Triggers the Other order (OTO) :</strong> This order
              is the opposite of an OCO order, because instead of cancelling an
              order uponfilling one, it will trigger another order upon filling
              one.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  <section id="orderTypes" className="about section-bg foexBGIng psoeipeowews">
    <div className="container" data-aos="fade-up">
      <div className="section-title inner_div_text">
        <h3>
          Lot <span>Sizes</span>
        </h3>
        <p>
          In Forex, positions are quoted in terms of 'lots'.The common
          nomenclature is 'standard lot’, 'mini lot’, 'micro lot’, and 'nano
          lot’; we can see examples of each of these in the chart below and the
          number of units they each
        </p>
      </div>
      <div className="row flexverticals">
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <div className="at_table_row table-responsive" id="tablesopao">
            <table
              id="at_table"
              className="table table-responsive table-bordered  table-hover text-center c_table postmajorsp"
            >
              <tbody>
                <tr>
                  <td className="tdhiegt" style={{ color: "#fff" }}>
                    Lot
                  </td>
                  <td className="tdhiegt" style={{ color: "#fff" }}>
                    Numbers of Units
                  </td>
                </tr>
                <tr>
                  <td>Standard</td>
                  <td>100000</td>
                </tr>
                <tr>
                  <td>Mini</td>
                  <td>10000</td>
                </tr>
                <tr>
                  <td>Micro</td>
                  <td>1000</td>
                </tr>
                {/* <tr>
                  <td>Nano</td>
                  <td>100</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="posepw">
            <p>
              {" "}
              <strong>Represent: </strong>
            </p>
            <p>
              We will assume we are using standard lots, which control 100,000
              units per lot. Let's see how this affects pip value.
            </p>
            <p>
              EUR/JPY at an exchange rate of 100.50 (.01 / 100.50) x 100,000 =
              $9.95 per pip USD/CHF at an exchange rate of 0.9190(.0001 /.9190)
              x 100,000 = 510.88 per pip
            </p>
            <p>
              In currency pairs where the U.S. dollar is the quote currency, one
              standard lot will always equal $10 per pip, one mini-lot will
              equal $1 per pip, one micro-lost will equal .10 cents per pip, and
              a nano-lot is one penny per pip.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    id="majorecepsi"
    className="featured-services masteopsw aboutCopssdsd3"
  >
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h3>Major Economical Events</h3>
      </div>
      <div className="row">
        <div
          className="col-md-6 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="icon-box js-tilt">
            <h4 className="title">
              <a href="#" style={{ pointerEvents: "none" }}>
                Gross Domestic Products (GDP)
              </a>
            </h4>
            <p className="description">
              The GDP report is one of the most important of all economic
              indicators. It is the biggest measure of the overall state of the
              economy. The GDP number is released at 8:30 am EST on the last day
              of each quarter and it reflects the previous quarter's activity.
              The GDP is the aggregate (total) monetary value of all the goods
              and services produced by the entire economy during the quarter
              being measured; this does not include international activity
              however. The growth rate of GDP is the important number to look
              for.
            </p>
          </div>
        </div>
        <div
          className="col-md-6 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="icon-box js-tilt">
            <h4 className="title">
              <a href="#" style={{ pointerEvents: "none" }}>
                Trade Balance
              </a>
            </h4>
            <p className="description">
              {" "}
              Trade balance is a measure of the difference between imports and
              exports of tangible goods and services. The level of a country's
              trade balance and changes in exports vs. imports is widely
              followed and an important indicator of a country's overall
              economic strength. It's better to have more exports than imports,
              as exports help grow a country's economy and reflect the overall
              health of its manufacturing sector.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="aboutCopseree" className="about section-bg foexBGIng">
    <div className="container" data-aos="fade-up">
      {/* <div class="section-title">
               <h2>Fortune FX</h2>
               <h3>Find Out More <span>About Us</span></h3>
               <p>We have best experience in online forex trading, stocks, commodities, cryptos & stock market..</p>
               </div> */}
      <div className="row flexverticals mb-bop">
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Consumer Price Index (CPI)
          </h3>
          <div className="posepw">
            <p>
              {" "}
              A limit entry order is placed to either buy below the current
              market price or sell above the current market price. Thisis a bit
              tricky to understand at first so let me explain:
            </p>
            <p>
              {" "}
              If the EURUSD is currently trading at 1.3200 and you want to go
              sell the market if it reaches 1.3250, you can place a limit sell
              order and then when / if the market touches 1.3250 it will fill
              you short. Thus, the limit sell order is placed ABOVE current
              market price. If you want to buy the EURUSD at 1.3050 and the
              market is trading at 1.3100, you would place your limit buy order
              at 1.3050 and then if the market hits that level it will fill you
              long. Thus the limit buy order is placed BELOW current market
              price.
            </p>
          </div>
        </div>
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/consumerprice.jpeg" />
        </div>
      </div>
      <div className="row flexverticals mb-bop">
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/ProducerPriceIndex.jpeg" />
        </div>
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Producer Price Index (PPI)
          </h3>
          <div className="posepw">
            <p>
              The PPI report is the most widely used measure of inflation. This
              report is released at 8:30 am EST around the 15" of each month and
              it reflects the previous month's data. PPI measures the change in
              the cost of a bundle of consumer goods and services from month to
              month.
            </p>
          </div>
        </div>
      </div>
      <div className="row flexverticals mb-bop">
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Employment Indicator
          </h3>
          <div className="posepw">
            <p>
              {" "}
              The most important employment announcement occurs on the first
              Friday of every month at 8:30 am EST. This announcement includes
              the unemployment rate; which is the percentage of the work force
              that is unemployed, the number of new jobs created, the average
              hours worked per week, and average hourly earnings. This report
              often results in significant market movement. You will often hear
              traders and analysts talking about “NFP”, this means Non-Farm
              Employment report, and it is perhaps the one report each month
              that has the greatest power to move the markets.
            </p>
          </div>
        </div>
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img
            className="img-fluids"
            src="images/EmploymentIndicator.jpeg"
          />
        </div>
      </div>
    </div>
  </section>
  <section
    id="majorecepsi"
    className="featured-services masteopsw aboutCopssdsd3"
  >
    <div className="container-fluid" data-aos="fade-up">
      <div className="row">
        <div
          className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="icon-box js-tilt">
            <h4 className="title fullwo">
              <a href="#" style={{ pointerEvents: "none" }}>
                Non Farm Payroll (NFP)
              </a>
            </h4>
            <p className="description">
              Non-farm payrollis aterm used inthe U.S. to refer to any job with
              the exception of farm work, unincorporated self-employment and
              employment by private households, nonprofit organizations and the
              military and intelligence agencies. Proprietors are also excluded.
              The U.S. Bureau of Labor Statistics releases closely followed
              monthly data on non-farm payrolls as part of its Employment
              Situation Report, which is commonly known as the 'Jobs Report’.
              The headline figure—the change in the total number of non-farm
              payrolls compared to the previous month—is used as a gauge of
              economic health.
            </p>
          </div>
        </div>
        <div
          className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="icon-box js-tilt">
            <h4 className="title fullwo">
              <a href="#" style={{ pointerEvents: "none" }}>
                Federal Open Market Committee (FOMC)
              </a>
            </h4>
            <p className="description">
              {" "}
              The Federal Open Market Committee (FOMC) is the branch of the
              Federal Reserve Board that determines the direction of Monetary
              Policy. The FOMC meets eight times a year to discuss whether to
              maintain or change current policy. A vote to change policy would
              result in either buying or selling U.S. Government Securities on
              the open market to promote the growth of the national economy.
            </p>
          </div>
        </div>
        <div
          className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="icon-box js-tilt">
            <h4 className="title fullwo">
              <a href="#" style={{ pointerEvents: "none" }}>
                Durable Good Order
              </a>
            </h4>
            <p className="description">
              The durable goods orders report gives a measurement of how much
              people are spending on longer-term purchases, these are defined as
              products that are expected to last more than three years. The
              report is released at 8:30 am EST around the 26" of each month and
              is believed to provide some insight into the future of the
              manufacturing industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="aboutCopseree" className="about section-bg foexBGIng">
    <div className="container" data-aos="fade-up">
      {/* <div class="section-title">
               <h2>Fortune FX</h2>
               <h3>Find Out More <span>About Us</span></h3>
               <p>We have best experience in online forex trading, stocks, commodities, cryptos & stock market..</p>
               </div> */}
      <div className="row flexverticals mb-bop nomgst">
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Retail Sales Index
          </h3>
          <div className="posepw">
            <p>
              {" "}
              The Retail Sales Index measures goods sold within the retail
              industry, from large chains to smaller local stores, it takes a
              sampling of a set of retail stores across the country. The Retail
              Sales Index is released at 8:30 am EST around the 12th of each
              month; it reflects data from the previous month. This report is
              often revised fairly significantly after the final numbers come
              out.
            </p>
          </div>
        </div>
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/RetailSalesIndex.jpeg" />
        </div>
      </div>
      <div className="row flexverticals mb-bop nomgst">
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/housingData.jpeg" />
        </div>
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Housing Data
          </h3>
          <div className="posepw">
            <p>
              Housing data includes the number of new homes that a country began
              building that month as well as existing home sales. Residential
              construction activity is a major cause of economic stimulus for a
              country and so it's widely followed by Forex participants.
              Existing home sales are a good measure of economic strength of a
              country as well; low existing home sales and low new home starts
              are typically a sign of a sluggish or weak economy.
            </p>
          </div>
        </div>
      </div>
      <div className="row flexverticals mb-bop nomgst">
        <div
          className="col-lg-8 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h3 className="titleSubTextspan testatepo titleChangeps">
            Interest Rates
          </h3>
          <div className="posepw">
            <p>
              Interest rates are the main driver in Forex markets; all of the
              above-mentioned economic indicators are closely watched by the
              Federal Open Market Committee in order to gauge the overall health
              of the economy. The Fed can use the tools at its disposable to
              lower, raise, or leave interest rates unchanged, depending on the
              evidence it has gathered on the health of the economy. While
              interest rates are the main driver of Forex price action, all of
              the above economic indicators are also very important. Fed
              meetings held eight times a year.
            </p>
          </div>
        </div>
        <div className="col-lg-4 " data-aos="fade-up" data-aos-delay={100}>
          <img className="img-fluids" src="images/intrestRate.jpeg" />
        </div>
      </div>
    </div>
  </section>
</>

    </div>
  );
};

export default Academy;
