import "./App.css";
import "./Css/Style.css";
import "./Css/main7dd3.css";
import "./Css/slick-theme.css";
import "./Css/slick.css";
import "./Css/style7dd3.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Componets/ComanComponet/Layout";
import Home from "./Componets/Pages/Home";
import Aboutus from "./Componets/Pages/Aboutus";
import FAQ from "./Componets/Pages/FAQ";
import Forex from "./Componets/Pages/Forex";
import Metals from "./Componets/Pages/Metals";
import Stocks from "./Componets/Pages/Stocks";
import Indices from "./Componets/Pages/Indices";
import MT5 from "./Componets/Pages/MT5";
import Accounts from "./Componets/Pages/Accounts";
import EconomicCalendar from "./Componets/Pages/EconomicCalendar";
import Livechart from "./Componets/Pages/Livechart";
import Liveprice from "./Componets/Pages/Liveprice";
import Education from "./Componets/Pages/Education";
import IntruductingBroker from "./Componets/Pages/IntruductingBroker";
import Pagenotfound from "./Componets/Pagenotfound";
import Contactus from "./Componets/Pages/Contactus";
import Academy from "./Componets/Pages/Academy";
import MarginCalculator from "./Componets/Pages/MarginCalculator";
import PIPclaculator from "./Componets/Pages/PIPclaculator";
import Regulation from "./Componets/Pages/Regulation";
import Modal from "./Componets/Modal";
import InquiryForm from "./Componets/Pages/InquireForm";
import React, { useState } from "react";
// import { NotificationProvider } from "./NotificationContext";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path={"/"} element={<Home />} />
            <Route path={"/aboutus"} element={<Aboutus />} />
            <Route path={"/faq"} element={<FAQ />} />
            <Route path={"/forex"} element={<Forex />} />
            <Route path={"/Metals"} element={<Metals />} />
            <Route path={"/Stocks"} element={<Stocks />} />
            <Route path={"/Indices"} element={<Indices />} />
            <Route path={"/mt5"} element={<MT5 />} />
            <Route path={"/accounts"} element={<Accounts />} />
            <Route path={"/EconomicCalendar"} element={<EconomicCalendar />} />
            <Route path={"/Livechart"} element={<Livechart />} />
            <Route path={"/Liveprice"} element={<Liveprice />} />
            <Route path={"/Education"} element={<Education />} />
            <Route path={"/Partners"} element={<IntruductingBroker />} />
            <Route path={"/contactus"} element={<Contactus />} />
            <Route path={"/Academy"} element={<Academy />} />
            <Route path={"/margin-calculator"} element={<MarginCalculator />} />
            <Route path={"/pip-calculator"} element={<PIPclaculator />} />
            <Route path={"/regulation"} element={<Regulation />} />
          </Route>
          <Route path={"/*"} element={<Pagenotfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
