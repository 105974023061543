import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const TradNow = () => {
  return (
    <div>
        <section className='tradesection'>
            <Container>
                <Row>
                    <div className="text-center tradenowtext">
                        <h2>Trade With {process.env.REACT_APP_APPNAME} Now</h2>
                    </div>
                </Row>
                <Row className=''>
                    <Col className='text-center Tradenowbtncol'>

                <Link to={process.env.REACT_APP_REGISTER} target="_blank" className='commanbtn'>Open Live Account</Link>
                   

                <Link to={process.env.REACT_APP_DEMO} target="_blank" className='commanbtn'>Open Demo Account</Link>
                    </Col>
                    
                </Row>
            </Container>
        </section>
    </div>
  )
}

export default TradNow