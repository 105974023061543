import React from 'react'
import PageBanner from '../PageBanner'
import { Container,Row,Col } from 'react-bootstrap'
import { EconomicCalendar } from "react-ts-tradingview-widgets";
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';
const Banner={

  backgroundimgage:"./images/6b71e1fb22f7f322b791588f1dc19183.jpeg",
  Title:"PIP Calculator",
  
  text:`Stay updated with our Pip Calculator. Keep track of upcoming economic events and their effects on market movements.`

}

const PIPclaculator = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div>
        <PageBanner backgroundimgage={Banner.backgroundimgage} Title={Banner.Title} text={Banner.text} />
    <section className='m-0 p-0'>
      <Container>
        <Row>
    
          <div className="section-title inner_div_text">
            <h2>Trading Tool</h2>
            <h3> Pip<span>Calculator</span></h3>
            <p>With years of experience in forex trading, our company has built a strong reputation for trust and reliability, with a commitment to ensuring the best trading experience for our clients.</p>
           
          
           
          </div>
        </Row>

      </Container>
    </section>
    <section className='m-0 '>
      <Container>
      <div className="marginclac row">
  <div className="text-center ">
    <div
      id="pivot-point-calculator-507079"
      style={{ position: "relative", maxWidth: "100%" }}
    >
      <iframe
        calc-id="ifrm-cbf-pivot-point-calculator-507079"
        scrolling="no"
        widgettype="calculator"
        src="https://www.cashbackforex.com/widgets/pivot-point-calculator?IsDisplayTitle=false&TopPaneStyle=YmFja2dyb3VuZDogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggYmxhY2s7IGJvcmRlci1ib3R0b206IG5vbmU7IA==&BottomPaneStyle=YmFja2dyb3VuZDogd2hpdGU7IGJvcmRlcjogc29saWQgMXB4IGJsYWNrOyBjb2xvcjogYmxhY2s7&ButtonStyle=YmFja2dyb3VuZDogYmxhY2s7IGNvbG9yOiB3aGl0ZTsgYm9yZGVyLXJhZGl1czogMjBweDs=&TitleStyle=dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==&TextboxStyle=YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==&ContainerId=pivot-point-calculator-507079&CompactType=large&HighlightColor=%23ffff00&IsShowEmbedButton=true"
        title="Pivot point calculator"
        style={{ width: "100%", border: 0 }}
        className='piphegit'
      />
      <a
        href="https://www.cashbackforex.com/tools/pivot-point-calculator#popout"
        target="_blank"
        title="Pivot point calculator"
        style={{ top: 7, right: 8, lineHeight: 0, background: "transparent" }}
      >
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mP8z/CfiYEIwDiqkL4KAYFAFAs8gr9XAAAAAElFTkSuQmCC"
          alt="Pivot point calculator"
          style={{ border: "0px solid", padding: 0 }}
        />
      </a>
    </div>
  </div>
</div>


       
      </Container>
    </section>

        
    </div>
  )
}

export default PIPclaculator