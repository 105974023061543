import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PageBanner from '../PageBanner'
import { Link } from 'react-router-dom'
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Banner={
    backgroundimgage:"./images/10701724_19187756.jpg",
    Title:"Contact Us",
    
    text:``

}

const Contactus = () => {
  return (
    <div>
        <PageBanner backgroundimgage={Banner.backgroundimgage} Title={Banner.Title} text={Banner.text} />

        {/* <section>
            
            <Container>
                <Row className='conactrow'>
                    <Col lg={3} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/icons8-call-100 (1) 2.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5>Call on</h5>
                                <p>{process.env.REACT_APP_MOBILENO}</p>

                            </div>
                            
                        </div>

                    </Col>
                    <Col lg={3} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/icons8-email-100.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5>Eamil On</h5>
                                <p>{process.env.REACT_APP_EMAIL}</p>

                            </div>
                            
                        </div>

                    </Col>
                    <Col lg={3} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/icons8-location-100 (1) 2.png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5>Physical Address</h5>
                                <p>{process.env.REACT_APP_PHYSICAL_ADDRESS}</p>

                            </div>
                            
                        </div>

                    </Col>
                    <Col lg={3} md={6} sm={12} className='contactcol' >
                        <div className="contactbox">
                            <div className="iconcintact">
                                <img src="/images/icons8-headphone-100 (3).png" alt=""  />
                            </div>
                            <div className='contactboxtext'>
                                <h5>Support</h5>
                                <p>24/7</p>

                            </div>
                            
                        </div>

                    </Col>
                </Row>
                <Row className='titlerow2'>
            <div className="text-center centerrow">
                <h2 className="sectiontitle ">Write to us</h2>
            </div>

        </Row>
        <Row className='conactformrow'>
            <Col className='text-center ' >
            <form action="" className='contactfrom'>
                <input type="text" name='name' placeholder='Enter Name' /> <br />
                <input type="email" name='email' placeholder='Enter Email' />  <br />
                <input type="number" name='mobileno' placeholder='Enter Mobile No.' /> <br />
                <textarea name="message" id=""  rows="" placeholder='Enter Your messages'></textarea><br />
                <div className='contactbtn'>

                <Link to={"/"}  className='commanbtn' target='_blank' style={{margin:'50px 0'}}>SUBMIT</Link>

                </div>

            </form>
            </Col>
            
        </Row>
            </Container>
        </section> */}
        <section id="contact" className="contact section-bg m-0">
  <div className="container aos-init aos-animate" data-aos="fade-up">
    <div className="section-title">
      <h2>Contact Us</h2>
      <h3>
      Contact  <span>Us</span>
      </h3>
      <p className="mepsoeo mt-2">
      For any inquiries regarding our comprehensive offerings, please feel free to contact us or complete the form below with your information. Our team will promptly get in touch with you. Thank you for considering Space World Capital Ltd as your trusted partner in the financial markets.
      </p>
    </div>
    <div className="row flexpowe">
      <div className="col-lg-6">
        <div className="row">
          <div className="col-md-12">
            <div
              className="info-box ospep aos-init aos-animate"
              data-aos="fade-up"
            >
              <i class="fa-solid fa-location-dot"></i>
              <h3>Our Address</h3>
              <p className="mgTextDiv">
                <strong>{process.env.REACT_APP_APPNAME} Physical Address </strong>
                {process.env.REACT_APP_PHYSICAL_ADDRESS}

              
              </p>
              <p className="mgTextDiv nomgsj">
                <strong>{process.env.REACT_APP_APPNAME} Registered office is at</strong>Misa Building, B.P. 724, Fomboni, Island of Mohéli, Comoros Union
              </p>
              <div className="social-links sospde">
                <Link
                  
                  target="_blank"
                  className=""
                  to={'https://www.t.me/SWC1603'}
                >
               <i class="fa-brands fa-telegram"></i>
                </Link>
                <Link

                  target="_blank"
                  className=""
                  to={'https://www.instagram.com/invites/contact/?i=7v7pz1k2ola1&utm_content=u9cf1c1'}
                >
                 <i class="fa-brands fa-instagram"></i>
                </Link>
                <Link
                
                  target="_blank"
                  className=""
                  to={'https://x.com/capital_sp59931?t=2YJpROCAA48kyjec4x0c4A&s=09'}
                >
                 <i class="fa-brands fa-x-twitter"></i>
                </Link>
              </div>
              <div className="contactBlsodp">
                <div className="flexpos marketforex">
                  <Link target='_blank' to={'https://api.whatsapp.com/send?phone=971581090829'}>
                  <i class="fa-brands fa-whatsapp"></i>
                    <span>WhatsApp</span>
                  </Link>
                </div>
                {/* <div class="flexpos marketforexIndia">
                  <a href="https://wa.me/971508942696">
                     <i class="bx bxl-whatsapp"></i>
                     <span>For Indian Indices</span>
                  </a> 
                </div>*/}
                <div className="flexpos marketforexSkype">
                  <Link  target='_blank'>
                  <i class="fa-solid fa-phone"></i>
                    <span>{process.env.REACT_APP_MOBILENO}</span>
                  </Link>
                </div>
                <div className="flexpos marketforexSkypeMail" style={{width:"100%"}}>
                  <Link target='_blank' >
                  <i class="fa-solid fa-envelope"></i>
                    <span>support@spaceworldcapital.com</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*   <div class="col-md-6">
          <div class="info-box mt-4" data-aos="fade-up" data-aos-delay="100">
            <i class="bx bxl-youtube"></i>
            <h3>Email Us</h3>
            <p>info@example.com<br>contact@example.com</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info-box mt-4" data-aos="fade-up" data-aos-delay="100">
            <i class="bx bx-phone-call"></i>
            <h3>Call Us</h3>
            <p>+1 5589 55488 55<br>+1 6678 254445 41</p>
          </div>
        </div> */}
        </div>
      </div>
      <div className="col-lg-6 mt-4 mt-lg-0">
        <form
          id="contact_form1"
          method="post"
          action="#"
          className="php-email-form w-100 aos-init aos-animate"
          data-aos="fade-up"
          noValidate="novalidate"
          role="form"
        >
          <div className="row">
            <div className="col-md-12 form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Your Name"
                required=""
                aria-required="true"
              />
            </div>
            <div className="col-md-12 form-group mt-3 mt-md-0">
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="Your Email"
                required=""
                aria-required="true"
              />
            </div>
            <div className="col-md-12 form-group mt-3 mt-md-0">
              <input
                type="number"
                className="form-control"
                name="number"
                id="number"
                placeholder="Your Number"
                required=""
                aria-required="true"
              />
            </div>
            <div className="col-md-12 form-group">
              <input
                type="text"
                name="subject"
                className="form-control"
                id="name"
                placeholder="Your Subject"
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className="col-md-12 form-group mt-3 mt-md-0">
            <textarea
              placeholder="Message"
              name="message"
            style={{height:"200px"}}
              data-gramm="false"
              wt-ignore-input="true"
              defaultValue={""}
            />
          </div>
      
          <div className="my-3">
            <div className="loading">Loading</div>
            <div className="error-message" />
            <div className="sent-message">
              Your message has been sent. Thank you!
            </div>
          </div>
          <div className="text-center">
            <Link style={{background:"#ca9a08",padding:"10px 20px ",color:"#000",textDecoration:"none "}}>Send Message</Link>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Contactus