import React from "react";
import { useState } from "react";
import PageBanner from "../PageBanner";
import WhyChoseus from "../WhyChoseus";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
const Banner = {
  backgroundimgage: "./images/10701724_19187756.jpg",
  Title: "EDUCATION",

  text: `Education is an essential component for successful trading. That’s why you can now explore a variety of valuable resources and information for all traders. Scroll down and find out more!`,
};

const Education = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const [box1, setbox1] = useState(false);
  const [box2, setbox2] = useState(false);
  const [box3, setbox3] = useState(false);
  const [box4, setbox4] = useState(false);
  function hendleclick() {
    setbox1(true);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hendleclick2() {
    setbox1(false);
    setbox2(true);
    setbox3(false);
    setbox4(false);
  }
  function hendleclick3() {
    setbox1(false);
    setbox2(false);
    setbox3(true);
    setbox4(false);
  }
  function hendleclick4() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(true);
  }
  function hidedata() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hidedata2() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hidedata3() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  function hidedata4() {
    setbox1(false);
    setbox2(false);
    setbox3(false);
    setbox4(false);
  }
  return (
    <div>
      <PageBanner
        backgroundimgage={Banner.backgroundimgage}
        Title={Banner.Title}
        text={Banner.text}
      />
      <section className="m-0 p-0">
        <Container>
          <Row>
            <div className="section-title inner_div_text">
              <h2>EDUCATION</h2>
              <h3>
                Fundamental <span>Analysis</span>
              </h3>
              <p>
                Fundamental analysis is one of the cornerstones of investing,
                and gives you tools to help determine the value of different
                investments. From SWOT analysis to PE ratios, learn the tools of
                fundamental analysis here.
              </p>
            </div>
          </Row>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div className="fandamentalstep">
                <h4>1. Economic Indicators</h4>
                <p>
                  GDP, inflation, interest rates, employment data, and other key
                  indicators.Their impact on currency values.
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className="fandamentalstep">
                <h4>2. Central Bank Policies</h4>
                <p>
                  Role of central banks in forex.Understanding monetary policy
                  decisions.
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className="fandamentalstep">
                <h4>3. News and Events</h4>
                <p>
                  Trading around major economic events and news
                  releases.Calendar of economic events.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="educationsection">
        <Container>
          <Row>
            <div className="section-title inner_div_text">
              <h2>EDUCATION</h2>
              <h3>
                {" "}
                Basics of <span>Forex Trading </span>
              </h3>
            </div>
          </Row>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <div data-aos="fade-right">
                <div className="educationtitle">
                  <h2>1. Introduction to Forex</h2>
                  <p>
                    Definition of forex and its role in the global
                    economy.Understanding currency pairs and exchange rates.
                  </p>
                </div>
                <div className="educationtitle">
                  <h2>3. Key Terms</h2>
                  <p>
                    Pips, lots, leverage, margin, bid/ask price, spread, and
                    other essential terms.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12} data-aos="flip-left">
              <img src="./images/mt5 features.webp" alt="" width={"100%"} />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div data-aos="fade-left">
                <div className="educationtitle">
                  <h2>2. Currency Pairs</h2>
                  <p>
                    Major, minor, and exotic pairs. Understanding base and quote
                    currencies.
                  </p>
                </div>
                <div className="educationtitle">
                  <h2>4. Market Structure</h2>
                  <p>
                    Explanation of the interbank market and the role of brokers.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="text-center educationtitle" data-aos="flip-up">
              <h2>5. Market Participants</h2>
              <p>
                Overview of major players: central banks, commercial banks,
                institutional investors, retail traders, and brokers.
              </p>
            </div>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
        
            <Row>
              <div className="section-title inner_div_text">
                <h2>EDUCATION</h2>
                <h3>
                  {" "}
                  Technical <span>Analysis</span>
                </h3>
              </div>
            </Row>
    
          <Row>
            <Col lg={3} md={6} sm={12}>
              <div
                className="techniaclstep"
                onMouseOver={hendleclick}
                onMouseLeave={hidedata}
                data-aos="flip-left"
              >
                {box1 ? (
                  ""
                ) : (
                  <div className="titletehnical">
                    <img src="./images/icons8-trading-100 (3) 2.png" alt="" />
                    <h4>
                      {" "}
                      1.Candlestick <br /> Patterns
                    </h4>
                  </div>
                )}
                {box1 && (
                  <div>
                    <p className="technicaltext">
                      Basics of candlestick analysis.Common patterns like doji,
                      engulfing, and hammer.
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div
                className="techniaclstep"
                onMouseOver={hendleclick2}
                onMouseLeave={hidedata2}
                data-aos="flip-left"
              >
                {box2 ? (
                  ""
                ) : (
                  <div className="titletehnical">
                    <img src="./images/icons8-analysis-100 (1).png" alt="" />
                    <h4>
                      2. Support <br />& Resistance
                    </h4>
                  </div>
                )}
                {box2 && (
                  <div>
                    <p className="technicaltext">
                      Identifying and drawing support and resistance
                      levels.Trendlines and channels.
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              onMouseOver={hendleclick3}
              onMouseLeave={hidedata3}
              data-aos="flip-left"
            >
              <div className="techniaclstep">
                {box3 ? (
                  ""
                ) : (
                  <div className="titletehnical">
                    {" "}
                    <img src="./images/icons8-indicators-100.png" alt="" />
                    <h4>3. Indicators</h4>
                  </div>
                )}
                {box3 && (
                  <div>
                    <p className="technicaltext">
                      Moving averages, RSI MACD , and others.How to use
                      indicators to make trading decisions.
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              onMouseOver={hendleclick4}
              onMouseLeave={hidedata4}
              data-aos="flip-left"
            >
              <div className="techniaclstep">
                {box4 ? (
                  ""
                ) : (
                  <div className="titletehnical">
                    <img src="./images/icons8-bar-chart-100.png" alt="" />
                    <h4>4. Chart Patterns</h4>
                  </div>
                )}
                {box4 && (
                  <div>
                    <p className="technicaltext">
                      Head and shoulders, triangles, flags, and
                      pennants.Recognizing and trading chart patterns..
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Education;
