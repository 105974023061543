import React from "react";
import Mianslider from "../MainSlider";
import { Col, Container, Row } from "react-bootstrap";
import Ourprocucts from "../Ourprocucts";
import Dwonloadbeoswr from "../Dwonloadbeoswr";
import { TickerTape } from "react-ts-tradingview-widgets";
import Services from "../Services";
import Pricelist from "../Pricelist";
import Advantages from "../Advantages";
import { Link } from "react-router-dom";
import ServicesMain from "../ServicesMain";
import WhyChoseus from "../WhyChoseus";
import AOS from "aos";
import { ForexCrossRates } from "react-ts-tradingview-widgets";
import { useEffect } from "react";
import "aos/dist/aos.css";
import MainModals from "../MainModals";

const mt5 = {
  img: "./images/mt5.png",
  title: "The Next Generation trading Platform ",
  title2: "MetaTrader5",
  text: "MetaTrader 5 is a web-based trading platform designed to help Forex and stock traders automate trading using trading robots, signals and fundamental analysis.",
};
const secutiy = {
  img: "./images/space-world-01.png",
  title: "Security of Funds ",
  subtitle: " LOSS INSURANCE PER ACCOUNT ",
  text: "Our partners have grown along with  Group in the past two decades, and our latest entity, MEX Atlantic, is taking our success a step further by offering unparalleled security to our traders. MEX Atlantic has taken out an insurance policy with Lloyd’s of London, the world’s leading insurance market, that protects clients’ funds.",
};
const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <MainModals />
      <Mianslider />
      <TickerTape colorTheme="dark"></TickerTape>

      <div>
        <ul className="listPLesd">
          <li
            className="style-one js-tilt"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <span>Tight Raw Spreads from</span>
            <h2>0.0 pips</h2>
          </li>
          <li
            className="style-two js-tilt"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <span>Fee-Free Funding </span>
            <h2>$0</h2>
          </li>
          <li
            className="style-two js-tilt"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <span> Leverage Up to</span>
            <h2>1:500</h2>
          </li>
          <li
            className="style-one js-tilt"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <span>Support</span>
            <h2>24/7</h2>
          </li>
        </ul>
      </div>
      <section id="aboutUs" className="services m-0">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Why Choose</h2>
            <h3>
              Trading Forex with <span>{process.env.REACT_APP_APPNAME}</span>
            </h3>
            <h4 className="titleSubText">
              Space World Capital Ltd offers top-notch trading conditions, low
              spreads, and cutting-edge technologies.
            </h4>
            <p style={{ color: "#fff" }}>
              Space World Capital Ltd is the largest and safest online currency
              trading platform.We have unrivalled knowledge of equities,
              commodities, cryptocurrencies, online FX trading, and stock market
              trading.
            </p>
          </div>
        </div>
        <div className="container-fluid">
          <div className="counts" style={{ padding: "20px 0" }}>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 my-3"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="count-box js-tilt newlook2 ">
                  <div className="newlook">
                    <img src="images/graph.png" />
                  </div>
                  <div>
                    <span lass="purecounterCounts">0.2</span>
                    <p>SPREAD AS LOW AS</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6  my-3 "
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="count-box js-tilt newlook2 ">
                  <div className="newlook">
                    <img src="images/leverage.png" />
                  </div>
                  <div>
                    <span lass="purecounterCounts">500</span>
                    <p>Leverage Up to 1</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6  my-3 "
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="count-box js-tilt newlook2 ">
                  <div className="newlook">
                    <img src="images/invention.png" />
                  </div>
                  <div>
                    <span className="purecounterCounts">275+</span>
                    <p>INSTRUMENTS TO TRADE</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6  my-3 "
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="count-box js-tilt newlook2">
                  <div className="newlook">
                    <img src="images/cross-platform.png" />
                  </div>
                  <div>
                    {" "}
                    <span lass="purecounterCounts">MT5</span>
                    <p>Powerful Platform</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6  my-3 "
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="count-box js-tilt newlook2">
                  <div className="newlook">
                    <img src="images/deposit.png" />
                  </div>
                  <div>
                    <span className="purecounterCounts">24/7</span>
                    <p>CUSTOMER SERVICE</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6  my-3 "
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="count-box js-tilt newlook2">
                  <div className="newlook">
                    <img src="images/funding.png" />
                  </div>
                  <div>
                    <span lass="purecounterCounts">0</span>
                    <p>FEES ON WITHDRAWAL & DEPOSIT</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="StartTrad" className="services m-0">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Account</h2>
            <h3>
              3 Easy Steps to Begin <span>Trading Forex</span>{" "}
            </h3>
            <h4 className="titleSubText">
              Get Started with Forex Trading with Us. This is easy to do and
              only takes a few minutes!
            </h4>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt d-flex justify-content-center ">
                  <div className="newlook ">
                    <img
                      src="images/register.png"
                      alt="#"
                      style={{ width: "90%" }}
                    />
                  </div>
                </figure>
                <h4>Register</h4>
                <p>Provide your details to open the trading account.</p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt d-flex justify-content-center ">
                  <div className="newlook">
                    <img
                      src="images/funds.png"
                      alt="#"
                      style={{ width: "90%" }}
                    />
                  </div>
                </figure>
                <h4>Deposit</h4>
                <p>
                  Use the payment method of your choice to fund your trades.
                </p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt d-flex justify-content-center ">
                  <div className="newlook">
                    <img
                      src="images/trade.png"
                      alt="#"
                      style={{ width: "90%" }}
                    />
                  </div>
                </figure>
                <h4>Trade</h4>
                <p>
                  You can start trading as soon as you provide the necessary
                  KYC!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="StartTrad" className="services m-0">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Forex Trading</h2>
            <h3>
              Raw Spreads from <span>0.0 pips</span>{" "}
            </h3>
            <h4 className="titleSubText">MOST POPULAR FX PAIRS</h4>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <ForexCrossRates
              colorTheme="light"
              width={"100%"}
              height={450}
            ></ForexCrossRates>
          </div>
        </div>
      </section>
      <section id="featured-services" className="featured-services m-0">
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title">
            <h2>Market</h2>
            <h3>
              What can you <span>Trade ?</span>
            </h3>
            <h4 className="titleSubText">
              Over 70+ trading instruments across forex , commodities , indices,
              Stocks.
            </h4>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="icon-box js-tilt">
                <div className="icon">
                  <img src="images/forex.png" />
                </div>
                <h4 className="title">
                  <>FOREX</>
                </h4>
                <p className="description">
                  Trade 40 currency pairs including major, minor and crosses
                  with ultra-low spreads and immediate execution
                </p>
                <Link className="readmoreLink" to="/forex" target="_blank">
                  Instruments list
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="icon-box js-tilt">
                <div className="icon">
                  <img src="images/bar-chart.png" />
                </div>
                <h4 className="title">
                  <>Stocks</>
                </h4>
                <p className="description">
                  Take position over 65+ shares across UK, US and European
                  markets on popular shares including Amazon, Boeing, Facebook
                  and Netflix
                </p>
                <Link className="readmoreLink" to="/Stocks" target="_blank">
                  Instruments list
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="icon-box js-tilt">
                <div className="icon">
                  <img src="images/gold-ingot.png" />
                </div>
                <h4 className="title">
                  <>Metals</>
                </h4>
                <p className="description">
                  Trade on Metals (Gold, Silver & Copper) and Energy (Crude,
                  Brent & Natural gas)
                </p>
                <Link className="readmoreLink" to="/Metals" target="_blank">
                  Instruments list
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="icon-box js-tilt">
                <div className="icon">
                  <img src="images/cryptocurrency.png" />
                </div>
                <h4 className="title">
                  <>Indices</>
                </h4>
                <p className="description">
                  Trade on basket of top shares representing the performance of
                  a country’s economy
                </p>
                <Link className="readmoreLink" to="/Indices" target="_blank">
                  Instruments list
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt5download ">
        <Container>
          <div className="section-title">
            <h2>Trading Platform</h2>
            <h3>
              Trading <span>Platform</span>
            </h3>
            <h4 className="titleSubText">
              {process.env.REACT_APP_APPNAME} offers you to access easy access
              to innovative <br /> investment tools MT5– available for desktops,
              laptops, and all kinds of mobile devices.
            </h4>
          </div>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <div className="desktop">
                <div className="iconofdesktop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="61"
                    viewBox="0 0 54 61"
                    fill="#000"
                  >
                    <g clip-path="url(#clip0_116_418)">
                      <path
                        d="M52.6367 59.1645C51.2373 58.8687 49.8452 58.5585 48.4458 58.2772L23.8406 53.3648C23.5232 53.2999 23.4077 53.2061 23.4077 52.8671C23.4077 46.1345 23.4077 39.3827 23.4077 32.6116C23.4077 32.6116 23.4077 32.5395 23.4077 32.4169L52.8387 32.9146C52.8387 33.1527 52.8748 33.3475 52.8748 33.5494V58.6162C52.8663 58.7999 52.8469 58.983 52.817 59.1645H52.6367Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M23.4148 28.5866V27.959C23.4148 21.414 23.4148 14.8666 23.4148 8.31674C23.4148 7.84786 23.5447 7.69638 23.9991 7.59539C32.1503 5.98438 40.2993 4.36135 48.4457 2.7263L52.8819 1.84619V28.0816L23.4148 28.5866Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M1.12524 12.1615L19.2383 8.55481V28.6371L1.12524 28.9401V12.1615Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M1.12521 32.0488L19.267 32.3518V52.4269L15.7036 51.7055C11.1014 50.7918 6.5017 49.8709 1.90432 48.9428C1.00985 48.7625 1.12521 48.9428 1.12521 48.0122C1.12521 42.958 1.12521 37.9086 1.12521 32.864V32.0488Z"
                        stroke="#000"
                        stroke-width="2.16404"
                        stroke-miterlimit="10"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_116_418">
                        <rect
                          width="54"
                          height="59.7131"
                          fill="white"
                          transform="translate(0 0.533447)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="tiltleofdeskto">
                  <h3>
                    Platform for <br />{" "}
                    <span style={{ color: "#D39C60" }}>Desktop</span>
                  </h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_DESKTOP}>
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div
                className="desktop"
                style={{
                  backgroundImage: "url('/images/Web_Photo_Editor (1).jpg')",
                  backgroundSize: "cover",
                }}
              >
                <div className="iconofdesktop">
                  <img src="/images/ANDROID.SVG" alt="" width={100} />
                </div>
                <div className="tiltleofdeskto">
                  <h3>
                    Platform for <br />{" "}
                    <span style={{ color: "#D39C60" }}> Andorid </span>{" "}
                  </h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_ANDROID} target="_blank">
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div
                className="desktop"
                style={{ backgroundImage: "url('/images/ios (1).jpg')" }}
              >
                <div className="iconofdesktop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="49"
                    height="59"
                    viewBox="0 0 49 59"
                    fill="#000"
                  >
                    <g clip-path="url(#clip0_116_410)">
                      <path
                        d="M34.5241 57.3359H33.0955C31.8837 56.8959 30.6584 56.4965 29.4668 55.9819C26.7649 54.8461 23.7292 54.7952 20.9907 55.8398L16.9286 57.3089H15.2903C15.2903 57.3089 15.2428 57.248 15.2157 57.2412C13.3964 56.8695 11.746 55.9196 10.5106 54.5332C8.82677 52.7908 7.38112 50.8329 6.21148 48.7108C3.09046 43.2948 1.38438 37.5402 1.79736 31.2508C1.90344 28.9609 2.50891 26.7219 3.5712 24.6905C5.06674 21.8678 7.44425 19.6116 10.3413 18.2657C12.3543 17.2492 14.6513 16.9415 16.8608 17.3924C18.3503 17.7173 19.8058 18.2116 21.2614 18.6787C22.717 19.1458 24.3148 19.64 25.9193 19.3557C27.0479 19.1169 28.1567 18.7933 29.2366 18.3876C30.8052 17.7648 32.4566 17.3747 34.1381 17.2299C37.3293 17.0947 40.4461 18.2179 42.8175 20.3577C43.5689 20.9941 44.2324 21.7523 44.9635 22.4767C40.7796 25.0155 38.6945 28.624 39.0871 33.4104C39.4798 38.1969 42.0254 41.4939 46.4124 43.3963C46.3786 43.4979 46.365 43.5656 46.3379 43.6265C45.0162 46.7338 43.303 49.6597 41.24 52.3329C39.4865 54.628 37.5639 56.7537 34.5241 57.3359Z"
                        stroke="$000"
                        stroke-width="2.70804"
                        stroke-miterlimit="10"
                      ></path>
                      <path
                        d="M34.6933 1.63159C35.6682 7.29817 30.6312 14.3864 24.2132 14.224C24.0066 13.1938 24.0274 12.131 24.2742 11.1097C24.6604 9.35654 25.4334 7.71175 26.5368 6.29562C27.6401 4.8795 29.046 3.72776 30.6515 2.92462C31.3174 2.5854 32.0103 2.30233 32.7232 2.07836C33.3529 1.87526 34.0163 1.78053 34.6933 1.63159Z"
                        stroke="#000"
                        stroke-width="2.70804"
                        stroke-miterlimit="10"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_116_410">
                        <rect
                          width="47.6886"
                          height="58.69"
                          fill="white"
                          transform="translate(0.375732)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="tiltleofdeskto">
                  <h3>
                    Platform for <br />{" "}
                    <span style={{ color: "#D39C60" }}> iPhone </span>
                  </h3>
                </div>
                <div className="desktoplink">
                  <Link to={process.env.REACT_APP_IOS} target="_blank">
                    Download Now{" "}
                    <span className="btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height={12}
                        viewBox="0 0 17 12"
                        fill="none"
                      >
                        <path
                          d="M10.5 11L15.5 6L10.5 1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 6H1"
                          stroke="#F78F1E"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="aboutCops" className="about section-bg m-0">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 js-tilt"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <img
                src="/images/human-resources-concept-with-hand.jpg"
                className="img-fluid imgsao"
                alt=""
              />
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 padding-leo content d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <h3 className="titleSubTextspan">
                Boost Your Prospective Income Become A{" "}
                <span>{process.env.REACT_APP_APPNAME}</span> Introducing Broker
              </h3>
              <p className="fst-italic">
                Customised Rebate Scheme <br /> Marketing Tools <br /> Dedicated
                IB DashBoard
              </p>
              <h5 className="headSuae">
                {" "}
                With {process.env.REACT_APP_APPNAME}, Get The Highest IB
                Commission Possible *
              </h5>
              <Link
                to={process.env.REACT_APP_LOGIN}
                className="becomePas"
                target="_blank"
              >
                {" "}
                <i class="fa-solid fa-user-group" /> Become An IB
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <Ourprocucts/>
       </section> */}

      {/* <section>
        <Dwonloadbeoswr />
      </section>
      <section>
        <Container>
          <Row>
            <div className="text-center">
              <h2 className="sectiontitle">
                EXPLORE OUR MOBILE TRADING PLATFORMS
              </h2>
            </div>
          </Row>
        </Container>
        <Services
          img={mt5.img}
          title={mt5.title}
          title2={mt5.title2}
          text={mt5.text}
        />
      </section>

      <section>
        <Advantages />
      </section>
      <section>
        <ServicesMain
          img={secutiy.img}
          title={secutiy.title}
          subtitle={secutiy.subtitle}
          text={secutiy.text}
        />
      </section>

      <section className="ibmain">
        <Row>
          <Col>
            <div className="text-center ibmaintext">
              <h2 className="sectiontitletext">
                INTRODUCING BROKERS & INSTITUTIONAL PROGRAM
              </h2>
              <h6>
                Increase your profits with the highest rebates & commissions in
                the industry.
              </h6>
              <p>
                {process.env.REACT_APP_APPNAME} Group’s Institutional Programs
                include Introducing Broker all exclusively tailored to suit your
                business.
              </p>
              <Link to={"/"} className="commanbtn" target="_blank">
                BECOME A IB
              </Link>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <Container>
          <Pricelist />
        </Container>
      </section> */}
    </div>
  );
};

export default Home;
