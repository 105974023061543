import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import InquiryForm from "./Pages/InquireForm";

const MainModals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inquiries, setInquiries] = useState([]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleOpenModal();
  }, []);

  return (
    <div className="App">
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <InquiryForm onClose={handleCloseModal} />
      </Modal>

      <div className="inquiries">
        <ul>
          {inquiries.map((inquiry, index) => (
            <li key={index}>
              <strong>Name:</strong> {inquiry.name} <br />
              <strong>Email:</strong> {inquiry.email} <br />
              <strong>Mobile:</strong> {inquiry.phone} <br />
              <strong>Message:</strong> {inquiry.message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MainModals;
