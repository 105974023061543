import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";

export default class Mianslider extends Component {



  render() {
    var settings = {


      dots: false,
      fade: true,
      waitForAnimate: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      speed: 8000,
      autoplaySpeed: 8000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  
    return (
      <div >
       
        <Slider {...settings}>
          <div className="slider">

         
        <Row>
          <Col lg={6} md={6} sm={12}>
          <div className="slidertext">
                <h3>Unlock the potential of global markets</h3>
                <p>Empowering Your Journey Through Global Markets: Your Success, Our Commitment</p>
                <div className=" sliederbtn ">
                <Link to={process.env.REACT_APP_REGISTER} target="_blank" className='commanbtn'>Open Live Account</Link>
                <Link to={process.env.REACT_APP_DEMO} target="_blank" className='commanbtn'>Open Demo Account</Link>
              
                  </div>
              </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="imgcol1">
          <div className="sliderimg">
                <img src='./images/pngegg (5).png' alt="" />
              </div>
          </Col>
        </Row>
        </div>
          
          {/* <div className="slider slider2">

         
        <Row>
          <Col lg={6} md={6} sm={12}>
          <div className="slidertext">
                <h3> Trusted   broker that understands your needs.</h3>
                <p>Where Expertise Meets Opportunity: Navigating the Forex Markets with Trust and Technology.</p>
                <div className=" sliederbtn ">
                <Link to={"/"} target="_blank" className='commanbtn'>Open Live Account</Link>
                <Link to={"/"} target="_blank" className='commanbtn'>Open Demo Account</Link>
              
                  </div>
              </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="imgcol1">
          <div className="imgsider2">
                <img src='./images/pngegg (7).png' alt="" />
              </div>
          </Col>
        </Row>
        </div> */}
          
          <div className="slider slider2 ">

         
        <Row>
          <Col lg={6} md={6} sm={12}>
          <div className="slidertext">
                <h3>Trade with confidence, trade with clarity</h3>
                <p>Smart Trading, Trusted Results. Your Journey to Financial Success Begins Now</p>
                <div className=" sliederbtn ">
                <Link to={process.env.REACT_APP_REGISTER} target="_blank" className='commanbtn'>Open Live Account</Link>
                <Link to={process.env.REACT_APP_DEMO}target="_blank" className='commanbtn'>Open Demo Account</Link>
              
                  </div>
              </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="imgcol1">
          <div className="imgsider3">
                <img src='./images/pngegg.png' alt="" />
              </div>
          </Col>
        </Row>
        </div>
          
          
          
          
        </Slider>
      </div>
    );
  }
}