import React from 'react'
import PageBanner from '../PageBanner'
import { Container,Row,Col } from 'react-bootstrap'
import { EconomicCalendar } from "react-ts-tradingview-widgets";
import AOS from 'aos';
import { useEffect } from 'react'
import 'aos/dist/aos.css';
const Banner={

  backgroundimgage:"./images/6b71e1fb22f7f322b791588f1dc19183.jpeg",
  Title:"Economic Calendar",
  
  text:`Stay updated with our economic calendar. Keep track of upcoming economic events and their effects on market movements.`

}

const Economic_Calendar = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div>
        <PageBanner backgroundimgage={Banner.backgroundimgage} Title={Banner.Title} text={Banner.text} />
    <section className='m-0 p-0'>
      <Container>
        <Row>
    
          <div className="section-title inner_div_text">
            <h2>Trading Tool</h2>
            <h3>Economic <span>Calendar</span></h3>
            <p>Stay updated with our economic calendar. Keep track of upcoming economic events and their effects on market movementsAn economic calendar is a schedule of events and releases of specific data affecting the rise and fall of financial assets.</p>
            <p>Keep up with all the latest company updates and overhauls. Be in the know and read up on all that happens here at {process.env.REACT_APP_APPNAME} in our very own company news section. .
        
        Dive in, to our news section and find out all the latest news on the financial market as we post regular updates about the markets and their analysis.</p>
          
           
          </div>
        </Row>

      </Container>
    </section>
    <section className='m-0 '>
      <Container>
        <Row>
        <div className="section-title">
            <h2>Economic Calendar</h2>
            <h3>
            Stay updated with our <span>Economic Calendar.</span>
            </h3>
   
          </div>
        </Row>
        <Row>
        <EconomicCalendar colorTheme="light" height={500} width="100%"></EconomicCalendar>
        </Row>
      </Container>
    </section>

        
    </div>
  )
}

export default Economic_Calendar