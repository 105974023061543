import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const PageBanner = (props) => {
    const  bannerstyle ={

    }

  return (
    <div>
        <section    className="page-banner " style={{backgroundImage:`url(${props.backgroundimgage})`,height:"500px"  , margin:"0 !important",backgroundRepeat:"round"}}>
            <Container>
                <Row>
                   <Col className='text-center'>
                        <div className='bannertext'>
                            <h2>{props.Title}</h2>
                            <p>{props.text }</p>
                        </div>
                   </Col>
                </Row>
            </Container>
        </section>
    </div>
  )
}

export default PageBanner