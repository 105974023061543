import React from "react";
import PageBanner from "../PageBanner";
import WhyChoseus from "../WhyChoseus";
import { ForexCrossRates } from "react-ts-tradingview-widgets";
import { Container, Row, Col } from "react-bootstrap";
import Pricelist from "../Pricelist";
import TabsNavi from "../TabsNavi";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Banner = {
  backgroundimgage:
    "./images/26798908_blue_lights_and_geometric_shape_overlaping_hexagon_mesh_pattern.jpg",
  Title: "FOREX",

  text: `FOREX Benefit from the most liquid financial market and trade CFDs with more than`,
};

const Forex = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <PageBanner
        backgroundimgage={Banner.backgroundimgage}
        Title={Banner.Title}
        text={Banner.text}
      />

      <section className="featured-services_tables m-0 p-0 ">
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title inner_div_text">
            <h2>Forex</h2>
            <h3>
              Forex Trading with<span>{process.env.REACT_APP_APPNAME}</span>
            </h3>
            <p>
              Each trading platform has its own array of assets and features
              that shape your trading experience..
            </p>
            <p>
              Forex is the largest financial market in the world with a daily
              turnover of approximately $6.6 Trillion. It operates through a
              global network of banks, companies and individuals who constantly
              buy and sell currencies from each other. The Foreign Exchange
              market has no physical location and operates 24 hours a day. Refer
              to our Trading Hours and be informed on the relevant dates and
              trading sessions.
            </p>
            <p>
              Space World Capital Ltd Group offers Forex major, cross and exotic
              pairs to trade in the Forex markets with deep liquidity. Benefit
              from the best trading tools and cutting-edge MetaTrader 5 trading
              platforms, available on all our accounts.
            </p>
          </div>
        </div>
      </section>
      <section id="StartTrad" className="services " >
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Forex Trading</h2>
            <h3>
              Raw Spreads from <span>0.0 pips</span>{" "}
            </h3>
            <h4 className="titleSubText">MOST POPULAR FX PAIRS</h4>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <ForexCrossRates
              colorTheme="light"
              width={"100%"}
              height={450}
            ></ForexCrossRates>
          </div>
        </div>
      </section>

      <section>
        <Container>
          <Row>
            <Col>
              <div className="forextitle">
                <h2>WHAT IS FOREX?</h2>
                <p>
                  Forex is the largest financial market in the world with a
                  daily turnover of approximately $6.6 Trillion. It operates
                  through a global network of banks, companies and individuals
                  who constantly buy and sell currencies from each other. The
                  Foreign Exchange market has no physical location and operates
                  24 hours a day. Refer to our Trading Hours and be informed on
                  the relevant dates and trading sessions.
                </p>
                <p>
                  {process.env.REACT_APP_APPNAME} Group offers Forex major,
                  cross and exotic pairs to trade in the Forex markets with deep
                  liquidity. Benefit from the best trading tools and
                  cutting-edge MetaTrader 5 trading platforms, available on all
                  our accounts.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="forextitle">
                <h2>ONLINE TRADING</h2>
                <p>
                  You can trade currencies online through brokers. With a broker
                  like {process.env.REACT_APP_APPNAME} Group, you can trade
                  currencies online and take advantage of the constantly
                  fluctuating exchange rates. You can buy and sell different
                  currencies and make a profit as the exchange rates change.
                  Trading Forex online with a broker has many benefits. You can
                  trade Forex from anywhere that has an Internet connection.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="forextitle">
                <h2>TRADING WITH LEVERAGE</h2>
                <p>
                  Leverage is using a small amount of money to control a large
                  amount of currency. Most Forex traders and investors buy and
                  sell currency pairs using leverage. The leverage associated
                  with currency pairs is one of the biggest benefits of the
                  forex markets, but also one of the biggest risks. Leverage
                  gives traders and investors the potential to make large
                  profits or large losses. But, if investors manage risk and
                  limit leverage, they could capture the benefits of forex
                  investing, including the ability to trade 24 hours a day and
                  capitalize on different market trends. Leverage works by using
                  a deposit, known as margin, to provide you with increased
                  exposure to an underlying asset.
                </p>
                <p>
                  The best way to understand leverage is through an example of
                  how it affects your profit or loss potential. For example, if
                  you trade with no leverage at all and invest 1 thousand
                  dollars, for every 1% move in the market, you can gain or lose
                  $10, which equals 1% of one thousand dollars.
                </p>
                <p>
                  In comparison, if you were to invest the same 1 thousand and
                  trade using 1:100 leverage, the dollar value of your position
                  would be equal to one hundred thousand dollars.
                </p>
                <p>
                  1% of a hundred thousand equals one thousand, so for every 1%
                  move in the market, you can gain or lose one thousand dollars.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="forextitle">
                <h2>TRADE FOREX WITH {process.env.REACT_APP_APPNAME} GROUP</h2>
                <p>
                  Start trading with {process.env.REACT_APP_APPNAME} Group on
                  over 55 Forex pairs with the tightest spreads in the industry.
                </p>
                <h6>SPREADS</h6>
                <p>
                  g from 0.0 PIPS, {process.env.REACT_APP_APPNAME} Group offers
                  one of the most competitive spreads in the market.
                </p>
                <h6>LEVERAGE</h6>
                <p>
                  With up to 500:1 leverage, users can increase their buying
                  power, making a difference to the affordability of their
                  trades.
                </p>
                <h6>55+ CURRENCY PAIRS</h6>
                <p>
                  There is a wide number of currency pairs, and they are
                  classified into major, minor, and exotic currency pairs.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Forex;
