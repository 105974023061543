import React, { useState } from "react";
import "../../Css/inquiry.css";
import { useNotification } from "../Notification";

const InquiryForm = ({ onClose }) => {
  const showNotification = useNotification();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const response = await fetch(
        "https://spaceworldapi.unicorntechnology.co/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, phone, message }),
        }
      );

      if (!response.ok) {
        setError("Failed to send email. Please try again later.");
        showNotification(
          "error",
          "Failed to send email. Please try again later.",
          "Error"
        );
        return;
      }

      const result = await response.json();
      if (result?.status === 200) {
        setSuccess("Email sent successfully!");
        showNotification("success", "Email sent successfully!", "Success");
      } else {
        setError("Failed to send email. Please try again later.");
        showNotification(
          "error",
          "Failed to send email. Please try again later.",
          "Error"
        );
      }
    } catch (error) {
      setError("Failed to send email. Please try again later.");
      showNotification(
        "error",
        "Failed to send email. Please try again later.",
        "Error"
      );
    }

    onClose();
  };

  return (
    <div className="inquiry-form">
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
      <div
        className="modalImg"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src="/images/space-transparent.png" alt="" />
      </div>
      {/* <h2 className="inquiryColor">Inquiry Form</h2> */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Mobile:</label>
          <input
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="submit-button commanbtn">
            Submit
          </button>
        </div>
      </form>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
};

export default InquiryForm;
