import React from 'react'
import LogoHeader from '../LogoHeader'
import MianHeader from '../MianHeader'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { TickerTape } from "react-ts-tradingview-widgets";

const Header = () => {
 
 
  return (
    <div>
      
<LogoHeader/>
<MianHeader/>

    </div>
  )
}

export default Header