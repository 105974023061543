import React from 'react'
import PageBanner from '../PageBanner'
import WhyChoseus from '../WhyChoseus'
import { StockMarket } from "react-ts-tradingview-widgets";
import { Container,Row } from 'react-bootstrap';
import Pricelist from '../Pricelist';
import ServicesMain from '../ServicesMain';
import TradNow from '../TradNow';
import TabsNavi from '../TabsNavi';
const Banner={

    backgroundimgage:"./images/elegant-background-perfect-canva.jpg",
    Title:"STOCKS",
    
    text:`Buy and Sell shares on over 20,000 equities of the largest multinational companies listed on the top stock exchanges in the world in market capitalization with margins as low as 5%.`

}
const stocks={
    img:"./images/pngegg (4).png",
  title:`TRADE STOCKS `,


  text:`${process.env.REACT_APP_APPNAME} offers you the opportunity to trade the best quality stocks from the U.S., China, Europe, and other regions of the world.Stock trading in general is the buying and selling of shares of different companies to earn a profit from the difference between the buy price and the selling price.`
  
  }
const Stocks = () => {
  return (
    <div>
        <PageBanner backgroundimgage={Banner.backgroundimgage} Title={Banner.Title} text={Banner.text} />
        <section className='m-0 p-0'>
          <Container>
          <div className="section-title inner_div_text">
            <h2>STOCKS</h2>
            <h3>
            Stock Trading with<span>{process.env.REACT_APP_APPNAME}</span>
            </h3>
            <p>Space World Capital Ltd offers you the opportunity to trade the best quality stocks from the U.S., China, Europe, and other regions of the world.Stock trading in general is the buying and selling of shares of different companies to earn a profit from the difference between the buy price and the selling price.</p>
          
           
          </div>
          </Container>
        </section>
        <section className='m-0'>
        <Container>
            <Row>
            <div className="section-title">
            <h2>STOCKS Trading</h2>
            <h3>
            Stock  <span>Trading</span>{" "}
            </h3>
   
          </div>
            </Row>
            <Row>
            <div
  className="tradingview-widget-container"
  style={{ width: "100%", height: 450,background:"transparent",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",padding:"20px"}}
>
  <iframe
    scrolling="no"
    allowTransparency="true"
    frameBorder={0}
    src="https://www.tradingview-widget.com/embed-widget/symbol-overview/?locale=in#%7B%22symbols%22%3A%5B%5B%22Apple%22%2C%22AAPL%7C1D%22%5D%2C%5B%22Google%22%2C%22GOOGL%7C1D%22%5D%2C%5B%22Microsoft%22%2C%22MSFT%7C1D%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A450%2C%22colorTheme%22%3A%22light%22%2C%22showVolume%22%3Afalse%2C%22showMA%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Afalse%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Afalse%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22price-and-percent%22%2C%22chartType%22%3A%22area%22%2C%22maLineColor%22%3A%22%232962FF%22%2C%22maLineWidth%22%3A1%2C%22maLength%22%3A9%2C%22lineWidth%22%3A2%2C%22lineType%22%3A0%2C%22dateRanges%22%3A%5B%221d%7C1%22%2C%221m%7C30%22%2C%223m%7C60%22%2C%2212m%7C1D%22%2C%2260m%7C1W%22%2C%22all%7C1M%22%5D%2C%22utm_source%22%3A%22phoenixfxltd.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22phoenixfxltd.com%2Fstock%22%7D"
    title="symbol overview TradingView widget"
    lang="en"
    style={{
      userSelect: "none",
      boxSizing: "border-box",
      display: "block",
      height: "calc(100% - 32px)",
      width: "100%"
    }}
  />
  <div className="tradingview-widget-copyright">
    <a
      href="https://in.tradingview.com/?utm_source=phoenixfxltd.com&utm_medium=widget_new&utm_campaign=symbol-overview"
      rel="noopener nofollow"
      target="_blank"
    >
      <span className="blue-text" />
    </a>
  </div>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n\t.tradingview-widget-copyright {\n\t\tfont-size: 13px !important;\n\t\tline-height: 32px !important;\n\t\ttext-align: center !important;\n\t\tvertical-align: middle !important;\n\t\t/* @mixin sf-pro-display-font; */\n\t\tfont-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;\n\t\tcolor: #B2B5BE !important;\n\t}\n\n\t.tradingview-widget-copyright .blue-text {\n\t\tcolor: #2962FF !important;\n\t}\n\n\t.tradingview-widget-copyright a {\n\t\ttext-decoration: none !important;\n\t\tcolor: #B2B5BE !important;\n\t}\n\n\t.tradingview-widget-copyright a:visited {\n\t\tcolor: #B2B5BE !important;\n\t}\n\n\t.tradingview-widget-copyright a:hover .blue-text {\n\t\tcolor: #1E53E5 !important;\n\t}\n\n\t.tradingview-widget-copyright a:active .blue-text {\n\t\tcolor: #1848CC !important;\n\t}\n\n\t.tradingview-widget-copyright a:visited .blue-text {\n\t\tcolor: #2962FF !important;\n\t}\n\t"
    }}
  />
</div>

            </Row>
        </Container>
       </section>

       <section className='m-0 p-0'>
        <TradNow/>
       </section>
       <section id="StartTrad" className="services m-0">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Account</h2>
            <h3>
              Start Forex Trading <span>IN MINUTES</span>{" "}
            </h3>
            <h4 className="titleSubText">
              Start Forex Trading with Us. It’s Easy, and takes only a few
              minutes!
            </h4>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/register.png" alt="#" />
                </figure>
                <h4>Register</h4>
                <p>Register the trading account with your information.</p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/funds.png" alt="#" />
                </figure>
                <h4>Fund</h4>
                <p>Fund your trading with the payment method of your choice.</p>
              </div>
            </div>
            <div
              className="col-md-4 process js-tilt"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="process-step">
                <figure className="process-icon js-tilt">
                  <img src="images/trade.png" alt="#" />
                </figure>
                <h4>Trade</h4>
                <p>Submit the required KYC and you are ready to trade !</p>
              </div>
            </div>
          </div>
        </div>
      </section>
       
    </div>
  )
}

export default Stocks