import React, { createContext, useContext, useState } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const showNotification = (type, message, title) => {
    switch (type) {
      case "success":
        NotificationManager.success(message, title);
        break;
      case "error":
        NotificationManager.error(message, title);
        break;
      case "warning":
        NotificationManager.warning(message, title);
        break;
      case "info":
        NotificationManager.info(message, title);
        break;
      default:
        break;
    }
  };

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      <NotificationContainer />
    </NotificationContext.Provider>
  );
};
