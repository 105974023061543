import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
const Regulation = () => {
    useEffect(() => {
        AOS.init();
      }, []);
  return (
    <div>
      <section
        id="featured-services"
        className="featured-services  noBgImage my-5 "
      >
        <div className="container-fluid mobilemargin"  data-aos="fade-up">
          <div className="section-title inner_div_text">
            <h2>Regulation</h2>
            <h3>
               <span>  Regulation</span>
            </h3>
            <p>
            Space World Capital Ltd  is  incorporated under the laws of AUTONOMOUS ISLAND OF MWALI. Authorised and regulated by the Financial Service Commission (FSC) in AUTONOMOUS ISLAND OF MWALI (MOHÉLI) COMOROS UNION MWALI INTERNATIONAL SERVICES AUTHORITY.
            </p>
        
          </div>
        </div>
      </section>
    </div>
  )
}

export default Regulation