import React from 'react'
import { Outlet } from 'react-router'
import Header from './Header'
import Footer from './Footer'
import {Link} from 'react-router-dom'

const Layout = () => {
  return (
    <div>
        <Header/>
        <div className='socialmediawidget'>
          <Link className='instagram' target='_blank' to={'https://www.instagram.com/invites/contact/?i=7v7pz1k2ola1&utm_content=u9cf1c1'}><i class="fa-brands fa-instagram"></i></Link>
          <Link className='telegarm' target='_blank' to={'https://www.t.me/SWC1603'}><i class="fa-brands fa-telegram"></i></Link>
          <Link className='twitter' target='_blank' to={'https://x.com/capital_sp59931?t=2YJpROCAA48kyjec4x0c4A&s=09'}><i class="fa-brands fa-x-twitter"></i></Link>
          <Link className='facebook' target='_blank' to={'https://www.linkedin.com/in/space-world-capital-526934303?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fa-brands fa-linkedin"></i></Link>
          <Link className='whatsapp' target='_blank' to={'https://api.whatsapp.com/send?phone=971581090829'}><i class="fa-brands fa-whatsapp"></i></Link>
          <Link className='facebook' target='_blank' to={'https://facebook.com/spaceworldcapital'}><i class="fa-brands fa-facebook"></i></Link>
        </div>
        <Outlet/>
        <Footer/>
    </div>
  )
}

export default Layout